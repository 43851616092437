<template>
  <!-- Para adicionar mais possiblidades de icones, crie uma nova div com a diretiva v-if" -->
  <div class="m-4 box" :style='pstyle'>
    
    <div class="flex flex-column" v-if="cardLabel == 'amz'">
      <div class="m-auto heightAdjust">
        <img alt="logo" src="@/assets/logo-basa.svg" height="50">
      </div>
    </div>

    <div class="flex flex-column vertical" v-if="cardLabel == 'bcd'">
      <div class="m-auto heightAdjust">
        <img alt="logo" class="notwhiteback" src="@/assets/logo-brasilcard.svg" height="50">
      </div>
    </div>

    <div class="flex flex-column vertical" v-if="cardLabel == 'bmx'">
      <div class="m-auto heightAdjust">
        <img alt="logo" src="@/assets/logo-master.png" height="50">
      </div>
    </div>

    <div class="flex flex-column vertical" v-if="cardLabel == 'bs2'">
      <div class="m-auto heightAdjust">
        <img alt="logo" src="@/assets/logo-bs2.svg" height="50">
      </div>
    </div>

    <div class="flex flex-column" v-if="cardLabel == 'ccb'">
      <div class="m-auto heightAdjust">
        <img alt="logo" src="@/assets/logo-ccb.svg" height="50">
      </div>
    </div>

    <div class="flex flex-column" v-if="cardLabel == 'cds'">
      <div class="m-auto heightAdjust">
        <img alt="logo" src="@/assets/logo-credsis-redirect.svg" height="75">
      </div>
    </div>

    <div class="flex flex-column vertical" v-if="cardLabel == 'exb'">
      <div class="m-auto heightAdjust">
        <img alt="card-icon" src="@/assets/logo-bratech.svg" height="50">
        <span class="rightinstcss box1">Bratech Crédito</span>
      </div>
    </div>

    <div class="flex flex-column" v-if="cardLabel == 'let'">
      <div class="m-auto heightAdjust">
        <img alt="logo" src="@/assets/logo-lets.png" height="50">
      </div>
    </div>

    <div class="flex flex-column vertical" v-if="cardLabel == 'ori'">
      <div class="m-auto heightAdjust">
        <img alt="logo" src="@/assets/OI_blue.svg" height="50">
      </div>
    </div>

    <div class="flex flex-column vertical" v-if="cardLabel == 'voi'">
      <div class="m-auto heightAdjust">
        <img alt="logo" class="notwhiteback" src="@/assets/logo-voiter.png" height="50">
      </div>
    </div>

    <div class="flex flex-column vertical" v-if="cardLabel == 'zem'">
      <div class="m-auto heightAdjust">
        <img alt="logo" class="notwhiteback" src="@/assets/logo-zema-blue.png" height="50">
      </div>
    </div>

    <div class="flex flex-column vertical" v-if="cardLabel == 'csy'">
      <div class="m-auto heightAdjust">
        <img alt="logo" class="notwhiteback" src="@/assets/logo-credsystem.png" height="50">
      </div>
    </div>

    <div class="flex flex-column vertical" v-if="cardLabel == 'iniciadora' && urlIniciadora != null && urlIniciadora != 'null'">
      <div class="m-auto heightAdjust">
        <img alt="card-icon" :src="urlIniciadora" height="50">
      </div>
    </div>
    <div class="flex flex-column vertical" v-if="cardLabel == 'iniciadora' && (urlIniciadora == null || urlIniciadora == 'null')">
      <div class="m-auto heightAdjust">
        <img v-if="theme == 'amz' || theme == 'exb'" alt="logo-open-finance" src="@/assets/logo-open-finance.png" height="50">
        <img v-else alt="logo-open-banking" src="@/assets/logo-open-banking.png" height="50">
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getLogoIniciadora, getTheme } from '@/util/util.js';

export default defineComponent({
  name: 'TecbanRedirectCardShadow',
  props: {
    imgName: String,
    viewName: String,
    cardLabel: String,
    pstyle: String
  },
  data() {
    return {
      urlIniciadora: '',
      theme: ''
    };
  },
  methods: {
    test(message: string) : void {
      console.log(message);
    },
    goToView(viewName: string, params?: Record<string, string | number | (string | number)[] | null | undefined>) : void {
      this.$router.push({ name: viewName, params});
    },
    themeLogo(){
      this.theme = getTheme();
    }
  },
  mounted() {
    this.urlIniciadora = getLogoIniciadora();
  }
});
</script>

<style scoped>

.box {
  background: var(--page-background-color);
  box-shadow: var(--shadow-default);
  padding: 1em;
  box-sizing: border-box;
  border-radius: 15px;
  min-width: 150px;
  max-width: 150px;
  min-height: 124px;
  max-height: 124px;
}
.box :active {
  border: none;
}
.rightinstcss{
  margin-left: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
}
.vertical img, .vertical span{
  display: inline-block;
  vertical-align: middle;
}
.box1{
  color: var(--redirect-box1-title-color);
}
.box2{
  color: var(--redirect-box2-title-color);
}
.heightAdjust{
  align-items: center;
  vertical-align: middle;
  padding: 5px 0px 5px 0px;
}
.notwhiteback{
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  background-color: var(--redirect-logo-background-color);
}

</style>