<template>
  <div class="mb-2 history-consent-box">
    <div v-bind:class="getCssClassBox(cardStatus)">
      <div v-bind:class="getCssClass(cardStatus)">
        <p class="card card-status card-status-title mb-2 ml-2">{{cardStatusTitle}}</p>
        <p class="card card-status card-status-description ml-2">
          <span v-show='cardStatusTitle === "Aguardando Autorização"' class="mr-1">Limite para autorização:</span> 
          <span>
            {{ isSweeping ? "" : cardStatusTitle === 'Encerramento: ' ? 'Data de encerramento: ' : 'Data de vencimento: ' }}
            {{cardStatusTitle === "Aguardando Autorização" ? convertUtcToLocale(cardHistoryAwaitingDate) : convertUtcToLocale(cardStatusDescription)}}
          </span>
        </p>
      </div>
      <div class="row" v-bind:class="getCssClassDescriptionContainer(cardStatus)">
        <div class="pt-2">
          <div v-bind:class="getCssClassToggler(cardStatus)" ></div>
        </div>
        <div class="col-12">
          <p class="card card-info-title mb-1 ml-2">{{cardInfoTitle}}</p>
        </div>
          <Timeline :value="history" class="history-timeline">
            <template #marker="slotProps">
              <span class="custom-marker shadow-2" v-bind:class="getCssColor(slotProps.item.status)">
              </span>
            </template>
            <template #content="slotProps">
              <div class="card card-info-title mb-1 ml-2 history-content">
                <p class="card card-inner-text mb-1 ml-2 history-content-status">{{getStatus(slotProps.item.status)}}</p>
                <p class="card card-inner-text mb-1 ml-2">{{convertUtcToLocale(slotProps.item.date)}}</p>
                <p class="card card-inner-text mb-1 ml-2" v-if="slotProps.item.description && slotProps.item.description.trim()!=='' && slotProps.item.description.trim()!=='-'">{{slotProps.item.description}}</p>
                <p class="card card-inner-text mb-1 ml-2" v-if="slotProps.item.additionalInformation">Motivo: {{slotProps.item.additionalInformation}}</p>
              </div>
            </template>
          </Timeline>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {GetConsentCssClass, GetConsentStatus} from '@/util/util.js';
import moment from 'moment';


export default defineComponent({
  name: 'ConsentStatusCardHistory',
  props: {
    id: Number,
    detailView: String,
    cardStatus: String,
    cardStatusTitle: String,
    cardStatusDescription: String,
    cardHistoryAwaitingDate: String,
    cardInfoTitle: String,
    consentID: String,
    isSweeping: Boolean,
    history: [Object]
  },
  created(){
    // console.log(this.isSweeping);
  },
  methods: {
    test(message: string) : void {
      console.log(message);
    },
    getCssClassBox(status: string) : string {
      return 'grid box box-' + status;
    },
    getCssColor(status: string) : string {
      return 'marker-' + GetConsentCssClass(status);
    },
    getStatus(status: string) {
      return GetConsentStatus(status);
    },
    getCssClass(status: string) : string {
      return 'col-12 card-header card-header-' + status;
    },
    getCssClassToggler(status: string) : string {
      return 'm-auto toggler toggler-' + status;
    },
    getCssClassDescriptionContainer(status: string) : string {
      return 'card-status-description-container card-status-description-container-' + status;
    },
    getCssDebtorData(haveDebtorData: boolean) : string {
      return haveDebtorData ? 'display: block' : 'display: none';
    },
    convertUtcToLocale(date: Date | string) {
      return moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss').replace(' ', ' - ');
    }
  }
});
</script>

<style scoped>

.box {
  background-color: #FFFFFF;
  box-shadow: var(--shadow-default);
  border-radius: 8px;
  margin: 1em
}
.box-waiting {
  background-color: var(--consultconsent-awaiting-background-color);
}
.box-consumed {
  background-color: var(--consultconsent-consumed-background-color);
}
.box-revoked {
  background-color: var(--consultconsent-revoked-background-color);
}
.box-authorised {
  background-color: var(--consultconsent-authorised-background-color);
}
.box-expired {
  background-color: var(--consultconsent-expired-background-color);
}
.box-rejected {
  background-color: var(--consultconsent-rejected-background-color);
}
.box-unavailable {
  background-color: var(--consultconsent-unavailable-background-color);
}
.marker-waiting {
  background-color: var(--consultconsent-awaiting-background-color);
}
.marker-consumed {
  background-color: var(--consultconsent-consumed-background-color);
}
.marker-revoked {
  background-color: var(--consultconsent-revoked-background-color);
}
.marker-authorised {
  background-color: var(--consultconsent-authorised-background-color);
}
.marker-expired {
  background-color: var(--consultconsent-expired-background-color);
}
.marker-rejected {
  background-color: var(--consultconsent-rejected-background-color);
}
.marker-unavailable {
  background-color: var(--consultconsent-unavailable-background-color);
}

.card {
  font-style: normal;
  display: flex;
  align-items: center;
}
.card-header {
  min-height: 74px;
  min-width: 312px;
  border-radius: 8px 8px 0px 0px;
  padding: 0.5em;
}

.toggler {
  border-radius: 8px;
  min-height:6px;
  width:37px;
}

.card-header-waiting {
  background: var(--consultconsent-awaiting-background-color);
}
.toggler-waiting {
  background: var(--consultconsent-awaiting-background-color);
}

.card-header-consumed {
  background: var(--consultconsent-consumed-background-color);
}
.toggler-consumed {
  background: var(--consultconsent-consumed-background-color);
}

.card-header-revoked {
  background: var(--consultconsent-revoked-background-color);
}
.toggler-revoked {
  background: var(--consultconsent-revoked-background-color);
}

.card-header-authorised {
  background: var(--consultconsent-authorised-background-color);
}
.toggler-authorised {
  background: var(--consultconsent-authorised-background-color);
}

.card-header-expired {
  background: var(--consultconsent-expired-background-color);
}
.toggler-expired {
  background: var(--consultconsent-expired-background-color);
}

.card-header-rejected {
  background: var(--consultconsent-rejected-background-color);
}
.toggler-rejected {
  background: var(--consultconsent-rejected-background-color);
}

.card-header-unavailable {
  background: var(--consultconsent-unavailable-background-color);
}
.toggler-unavailable {
  background: var(--consultconsent-unavailable-background-color);
}

.card-header-waiting .card-status {
  color: var(--consultconsent-awaiting-text-color);
}
.card-header-rejected .card-status {
  color: var(--consultconsent-rejected-text-color);
}
.card-header-unavailable .card-status {
  color: var(--consultconsent-unavailable-text-color);
}
.card-header-consumed .card-status {
  color: var(--consultconsent-consumed-text-color);
}
.card-header-revoked .card-status {
  color: var(--consultconsent-revoked-text-color);
}
.card-header-authorised .card-status {
  color: var(--consultconsent-authorised-text-color);
}
.card-header-expired .card-status {
  color: var(--consultconsent-expired-text-color);
}
.card-status {
  font-weight: bold;
}
.card-status-title {
  font-size: 16px;
  line-height: 22px;
}

.card-status-description-container {
  border-radius: 8px;
  background-color: var(--page-background-color);
  width: 100%;
}
.card-status-description-container-waiting {
  border: 1px solid var(--consultconsent-awaiting-background-color);
}
.card-status-description-container-consumed {
  border: 1px solid var(--consultconsent-consumed-background-color);
}
.card-status-description-container-revoked {
  border: 1px solid var(--consultconsent-revoked-background-color);
}
.card-status-description-container-authorised {
  border: 1px solid var(--consultconsent-authorised-background-color);
}
.card-status-description-container-expired {
  border: 1px solid var(--consultconsent-expired-background-color);
}
.card-status-description-container-rejected {
  border: 1px solid var(--consultconsent-rejected-background-color);
}

.card-status-description-container-unavailable {
  border: 1px solid var(--consultconsent-unavailable-background-color);
}

.card-status-description {
  font-size: 12px;
  line-height: 12px;
}
.card-inner-text {
  color: var(--consultconsent-box-down-text-color);
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin:0;
  word-break: break-all;
}
.card-info-title {
  color: var(--consultconsent-box-down-title-color);
  font-weight: var(--consultconsent-box-down-title-weight);
  font-size: 20px;
  line-height: 24px;
}
.custom-marker {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    z-index: 1;
}
.history-timeline{
  margin-left: 15px;
  margin-top: 30px;
}
.history-content{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.history-content-status {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: var(--consultconsent-timeline-title-color);
}
::v-deep(.timeline-event-opposite) {
  display: none;
}
::v-deep(.timeline-event-content) {
  margin-top: -23px;
  margin-bottom: 40px;
}
::v-deep(.timeline-event-connector) {
  background-color: rgba(90, 90, 90, 1);
  width: 2px;
}
@media (max-width: 768px){
  .p-timeline.p-timeline-vertical .p-timeline-event-content{
    padding: 0 0.5rem 1rem 1rem;
  }
  .card {
    align-items: flex-start;
    flex-direction: column;
  }
}
</style>