import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/logo-open-finance.png'
import _imports_1 from '@/assets/logo-tecban-full.png'


const _hoisted_1 = {
  key: 0,
  id: "imageFlexContainer",
  class: "flex justify-content-center maradjust"
}
const _hoisted_2 = {
  key: 0,
  alt: "logo",
  src: _imports_0,
  height: "40"
}
const _hoisted_3 = {
  key: 1,
  alt: "logo",
  src: _imports_0,
  height: "40"
}
const _hoisted_4 = {
  key: 1,
  id: "imageFlexContainer",
  class: "flex justify-content-left maradjust"
}
const _hoisted_5 = {
  key: 0,
  alt: "logo",
  src: _imports_0,
  height: "40"
}
const _hoisted_6 = {
  key: 1,
  alt: "logo",
  src: _imports_0,
  height: "40"
}
const _hoisted_7 = {
  key: 2,
  id: "imageFlexContainer",
  class: "flex justify-content-center"
}
const _hoisted_8 = {
  key: 3,
  id: "imageFlexContainer",
  class: "flex justify-content-left"
}
const _hoisted_9 = {
  key: 4,
  id: "imageFlexContainer",
  class: ""
}
const _hoisted_10 = {
  key: 5,
  id: "imageFlexContainer",
  class: "flex justify-content-left maradjust"
}
const _hoisted_11 = {
  key: 0,
  alt: "logo",
  src: _imports_0
}
const _hoisted_12 = {
  key: 1,
  alt: "logo",
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.align == 'center')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.themeLogo === 'amz' || _ctx.themeLogo === 'exb')
            ? (_openBlock(), _createElementBlock("img", _hoisted_2))
            : (_openBlock(), _createElementBlock("img", _hoisted_3))
        ]))
      : _createCommentVNode("", true),
    (_ctx.align == 'left')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.themeLogo === 'amz' || _ctx.themeLogo === 'exb')
            ? (_openBlock(), _createElementBlock("img", _hoisted_5))
            : (_openBlock(), _createElementBlock("img", _hoisted_6))
        ]))
      : _createCommentVNode("", true),
    (_ctx.align == 'tecban-center')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            alt: "logo",
            src: _imports_1,
            width: "225"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.align == 'tecban-left')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            alt: "logo",
            src: _imports_1,
            width: "225"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.align == 'open-banking-center')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[2] || (_cache[2] = [
          _createElementVNode("img", {
            alt: "logo",
            src: _imports_0,
            width: "225"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.align == 'open-banking-left')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_ctx.themeLogo === 'amz' || _ctx.themeLogo === 'exb')
            ? (_openBlock(), _createElementBlock("img", _hoisted_11))
            : (_openBlock(), _createElementBlock("img", _hoisted_12))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}