import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/logo-basa.svg'
import _imports_1 from '@/assets/logo-brasilcard.svg'
import _imports_2 from '@/assets/logo-master.png'
import _imports_3 from '@/assets/logo-bs2.svg'
import _imports_4 from '@/assets/logo-ccb.svg'
import _imports_5 from '@/assets/logo-credsis-redirect.svg'
import _imports_6 from '@/assets/logo-bratech.svg'
import _imports_7 from '@/assets/logo-lets.png'
import _imports_8 from '@/assets/OI_blue.svg'
import _imports_9 from '@/assets/logo-voiter.png'
import _imports_10 from '@/assets/logo-zema-blue.png'
import _imports_11 from '@/assets/logo-credsystem.png'
import _imports_12 from '@/assets/logo-open-finance.png'
import _imports_13 from '@/assets/logo-open-banking.png'


const _hoisted_1 = {
  key: 0,
  class: "flex flex-column"
}
const _hoisted_2 = {
  key: 1,
  class: "flex flex-column vertical"
}
const _hoisted_3 = {
  key: 2,
  class: "flex flex-column vertical"
}
const _hoisted_4 = {
  key: 3,
  class: "flex flex-column vertical"
}
const _hoisted_5 = {
  key: 4,
  class: "flex flex-column"
}
const _hoisted_6 = {
  key: 5,
  class: "flex flex-column"
}
const _hoisted_7 = {
  key: 6,
  class: "flex flex-column vertical"
}
const _hoisted_8 = {
  key: 7,
  class: "flex flex-column"
}
const _hoisted_9 = {
  key: 8,
  class: "flex flex-column vertical"
}
const _hoisted_10 = {
  key: 9,
  class: "flex flex-column vertical"
}
const _hoisted_11 = {
  key: 10,
  class: "flex flex-column vertical"
}
const _hoisted_12 = {
  key: 11,
  class: "flex flex-column vertical"
}
const _hoisted_13 = {
  key: 12,
  class: "flex flex-column vertical"
}
const _hoisted_14 = { class: "m-auto heightAdjust" }
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 13,
  class: "flex flex-column vertical"
}
const _hoisted_17 = { class: "m-auto heightAdjust" }
const _hoisted_18 = {
  key: 0,
  alt: "logo-open-finance",
  src: _imports_12,
  height: "50"
}
const _hoisted_19 = {
  key: 1,
  alt: "logo-open-banking",
  src: _imports_13,
  height: "50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "m-4 box",
    style: _normalizeStyle(_ctx.pstyle)
  }, [
    (_ctx.cardLabel == 'amz')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "m-auto heightAdjust" }, [
            _createElementVNode("img", {
              alt: "logo",
              src: _imports_0,
              height: "50"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'bcd')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "m-auto heightAdjust" }, [
            _createElementVNode("img", {
              alt: "logo",
              class: "notwhiteback",
              src: _imports_1,
              height: "50"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'bmx')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "m-auto heightAdjust" }, [
            _createElementVNode("img", {
              alt: "logo",
              src: _imports_2,
              height: "50"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'bs2')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [
          _createElementVNode("div", { class: "m-auto heightAdjust" }, [
            _createElementVNode("img", {
              alt: "logo",
              src: _imports_3,
              height: "50"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'ccb')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[4] || (_cache[4] = [
          _createElementVNode("div", { class: "m-auto heightAdjust" }, [
            _createElementVNode("img", {
              alt: "logo",
              src: _imports_4,
              height: "50"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'cds')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[5] || (_cache[5] = [
          _createElementVNode("div", { class: "m-auto heightAdjust" }, [
            _createElementVNode("img", {
              alt: "logo",
              src: _imports_5,
              height: "75"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'exb')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[6] || (_cache[6] = [
          _createElementVNode("div", { class: "m-auto heightAdjust" }, [
            _createElementVNode("img", {
              alt: "card-icon",
              src: _imports_6,
              height: "50"
            }),
            _createElementVNode("span", { class: "rightinstcss box1" }, "Bratech Crédito")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'let')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[7] || (_cache[7] = [
          _createElementVNode("div", { class: "m-auto heightAdjust" }, [
            _createElementVNode("img", {
              alt: "logo",
              src: _imports_7,
              height: "50"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'ori')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[8] || (_cache[8] = [
          _createElementVNode("div", { class: "m-auto heightAdjust" }, [
            _createElementVNode("img", {
              alt: "logo",
              src: _imports_8,
              height: "50"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'voi')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[9] || (_cache[9] = [
          _createElementVNode("div", { class: "m-auto heightAdjust" }, [
            _createElementVNode("img", {
              alt: "logo",
              class: "notwhiteback",
              src: _imports_9,
              height: "50"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'zem')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[10] || (_cache[10] = [
          _createElementVNode("div", { class: "m-auto heightAdjust" }, [
            _createElementVNode("img", {
              alt: "logo",
              class: "notwhiteback",
              src: _imports_10,
              height: "50"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'csy')
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[11] || (_cache[11] = [
          _createElementVNode("div", { class: "m-auto heightAdjust" }, [
            _createElementVNode("img", {
              alt: "logo",
              class: "notwhiteback",
              src: _imports_11,
              height: "50"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'iniciadora' && _ctx.urlIniciadora != null && _ctx.urlIniciadora != 'null')
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("img", {
              alt: "card-icon",
              src: _ctx.urlIniciadora,
              height: "50"
            }, null, 8, _hoisted_15)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.cardLabel == 'iniciadora' && (_ctx.urlIniciadora == null || _ctx.urlIniciadora == 'null'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            (_ctx.theme == 'amz' || _ctx.theme == 'exb')
              ? (_openBlock(), _createElementBlock("img", _hoisted_18))
              : (_openBlock(), _createElementBlock("img", _hoisted_19))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 4))
}