<template>
  <div class="m-2 box">
    <div class="flex flex-column">
      <div class="mt-auto"><a @click="makeAction()" class="box-text"> {{ cardLabel }}</a></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TecbanSimpleCardShadow',
  props: {
    msg: String,
    cardLabel: String,
    action: Function
  },
  methods: {
    showMsg(message: string) : void {
      console.log(message);
    },
    makeAction(){
      this.$emit('action');
    },
  }
});
</script>

<style scoped>

.box {
  background-color: var(--home-card2-background-color);
  box-shadow: var(--shadow-default);
  border-radius: 8px;
  min-height: 59px;
  cursor: pointer;
  max-width: 500px;
}
.box-text {
  color: var(--home-card2-text-color);
  font-style: normal;
  font-weight: var(--home-card2-text-weight);
  font-size: 14px;
  line-height: 24px;
  display: flex;
  padding: 1em;
}
.box :active {
  border: none;
}
.box:active {
  border: 3px solid var(--home-card2-action-color);
}
.box:hover {
  background-color: var(--home-card2-hover-color);
}

</style>