<template>
    <div class="mb-2">
      <div class="ml-5 mr-5">
        <h3 class="text text-title fontP" v-if="title == 'Simulador de TPP'">{{ title }}</h3>
        <h3 class="text text-title" v-if="title != 'Simulador de TPP'">{{ title }}</h3>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleP',
  props: {
    title: String
  },
  methods: {
    test(message: string) : void {
      console.log(message);
    }
  }
});
</script>

<style scoped>

.text-title {
  color: var(--consultconsent-myconsents-title-color);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}
.fontP {
  text-align: center;
  font-size: 50px !important;
  line-height: 60px;
}
.titleMyConsents .text-title{
  font-weight: var(--consultconsent-myconsents-title-weight);
}

</style>