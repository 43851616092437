<template>

  <Dialog v-model:visible="customCalendar" style="height: auto; max-height: 100vh;" modal header="Veja todas as datas escolhidas">
    <Calendar id="calendar-exibicao" v-model="scheduledDatev4Local" inline selectionMode="multiple">
    </Calendar>
    <Cbutton
      rotulo="Voltar"
      tipo="button"
      :confirmacao="false"
      @botaoAtivado="customCalendar = false"
    />
  </Dialog>

  <div class="mb-2">
    <div v-bind:class="getCssClassBox(cardStatus)">
      <div v-bind:class="getCssClass(cardStatus)">
        <p class="card card-status card-status-title mb-2 ml-2">{{cardStatusTitle}}</p>
        <p class="card card-status card-status-description ml-2">{{cardStatusDescription}}</p>
      </div>
      <div class="row" v-bind:class="getCssClassDescriptionContainer(cardStatus)">
        <div class="pt-2">
          <div v-bind:class="getCssClassToggler(cardStatus)" ></div>
        </div>

        <!-- Resumo da Autorização -->
        <div class="col-12">
          <p class="card card-info-title mb-1 ml-2">{{cardInfoTitle}}</p>
        </div>

        <template v-if="initiatorName != null && initiatorName != undefined && initiatorName != ''">
          <div class="col-12">
            <p class="card card-inner-title mb-2 ml-2">{{initiatorTitle}}</p>
            <p class="card card-inner-text mb-1 ml-2">{{initiatorName}}</p>
          </div>
        </template>

        <div class="col-12">
          <p class="card card-inner-title mb-2 ml-2">{{creationDateTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{creationDate}}</p>
        </div>
  
        <div class="col-12" v-bind:style="getCssDebtorData(haveDebtorAccount)">
          <p class="card card-inner-title mb-2 ml-2">{{payerDetailTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{payerCpf}}</p>
          <p v-if="payerName != null && payerName != undefined && payerName != ''" class="card card-inner-text mb-1 ml-2">{{payerName}}</p>
          <p v-if="payerBranch != null && payerBranch != undefined && payerBranch != ''" class="card card-inner-text mb-1 ml-2">{{payerBranch}}</p>  
          <p v-if="payerCc != null && payerCc != undefined && payerCc != ''" class="card card-inner-text mb-1 ml-2">{{payerCc}}</p>  
        </div>
        
        <div class="col-12">
          <p class="card card-inner-title mb-2 ml-2">{{beneficiaryDetailTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{beneficiaryCnpj}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{beneficiaryName}}</p>

          <template v-if="localInstrument == 'MANU'">
            <p class="card card-inner-text mb-1 ml-2">{{beneficiaryBankLabel}}: {{beneficiaryBankCode}}</p>
            <p class="card card-inner-text mb-1 ml-2">{{beneficiaryAggLabel}} {{beneficiaryAgg}}</p>
            <p class="card card-inner-text mb-1 ml-2">{{beneficiaryAccount}}</p>
          </template>

          <template v-if="localInstrument == 'DICT' || localInstrument == 'INIC'">
            <p class="card card-inner-text mb-1 ml-2">{{beneficiaryPixLabel}} {{beneficiaryPixValue}}</p>
          </template>

          <template v-if="localInstrument == 'QRDN'">
            <p class="card card-inner-text mb-1 ml-2">{{beneficiaryQRDinLabel}}</p>
            <p class="card card-inner-text mb-1 ml-2">{{beneficiaryPixLabel}} {{beneficiaryPixValue}}</p>
          </template>

          <template v-if="localInstrument == 'QRES'">
            <p class="card card-inner-text mb-1 ml-2">{{beneficiaryQREstLabel}}</p>
            <p class="card card-inner-text mb-1 ml-2">{{beneficiaryPixLabel}} {{beneficiaryPixValue}}</p>
          </template>

          <template v-if="paymentForm === 'TED' || paymentForm === 'TEF'">
            <p class="card card-inner-text mb-1 ml-2">{{beneficiaryBankLabel}} {{beneficiaryBankCode}}</p>
            <p class="card card-inner-text mb-1 ml-2">{{beneficiaryAggLabel}} {{beneficiaryAgg}}</p>
            <p class="card card-inner-text mb-1 ml-2">{{beneficiaryAccount}}</p>
          </template>

        </div>

        <!-- <div class="col-12"> -->

        <!-- Rejeitado por -->

        <!-- <div class="col-12" v-if="consentCreatorName != null && consentCreatorName != undefined && consentCreatorName != '' && consentCreatorName != '-'">
          <p class="card card-inner-title mb-2 ml-2">{{consentCreatorLabel}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{consentCreatorName}}</p>
        </div> -->

        <!-- Motivo da reijão -->

        <div class="col-12" v-if="consentRejectionReasonCode">
          <p class="card card-inner-title mb-2 ml-2">{{consentRejectionReasonCodeLabel}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{consentRejectionReasonCode}}</p>
        </div>

        <div v-if="initiationDate" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">{{initiationDateTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{initiationDate}}</p>
        </div>
        <div v-if="scheduledDate && cardStatus == 'revoked' && revocationReason !== undefined" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">{{revocationReasonTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{revocationReason}}</p>
        </div>
        <div v-if="scheduledDate" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">{{scheduledDateTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{scheduledDate}}</p>
        </div>
        <div v-if="scheduledDatev4" class="col-12">
          <p class="card card-inner-title mb-2 ml-2" v-if="typeScheduleV4 !== 'custom'">Data do primeiro pagamento</p>
          <p class="card card-inner-title mb-2 ml-2" v-else>Data dos pagamentos</p>
          <p class="card card-inner-text mb-1 ml-2" v-if="typeScheduleV4 !== 'custom'">{{scheduledDatev4}}</p>
          <p class="card card-inner-text mb-1 ml-2 font-14-click" v-else @click="customCalendar = true"><strong>Ver todas as datas</strong></p>
        </div>
        <div v-if="customMsgInfo" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Mensagem adicional</p>
          <p class="card card-inner-text mb-1 ml-2">{{customMsgInfo}}</p>
        </div>
        <div v-if="scheduledDatev4 && typeRecurrence() === 'semanal'" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Dia da semana do pagamento</p>
          <p v-if="v4NameWeek === 'SABADO' || v4NameWeek === 'DOMINGO'" class="card card-inner-text mb-1 ml-2">Todo {{ formatDayWeek() }} da semana</p>
          <p v-else class="card card-inner-text mb-1 ml-2">Toda {{ formatDayWeek() }} da semana</p>
        </div>
        <div v-if="scheduledDatev4 && typeRecurrence() === 'mensal'" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Dia do mês do pagamento</p>
          <p class="card card-inner-text mb-1 ml-2">Todo dia {{ v4DayMonth }} do mês</p>
        </div>
        <div v-if="recurrence" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Recorrência do pagamento ({{ typeRecurrence() }})</p>
          <div v-if="typeRecurrence() === 'diário'" class="card card-inner-text mb-1 ml-2">{{ recurrence }} dias seguidos</div>
          <div v-if="typeRecurrence() === 'semanal'" class="card card-inner-text mb-1 ml-2">{{ recurrence }} semanas</div>
          <div v-if="typeRecurrence() === 'mensal'" class="card card-inner-text mb-1 ml-2">{{ recurrence }} meses</div>
        </div>
        <div class="col-12" v-if="value">
          <p class="card card-inner-title mb-2 ml-2">{{valueTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{value}}</p>
        </div>
        <div class="col-12">
          <p class="card card-inner-title mb-2 ml-2">{{paymentFormTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{paymentForm}}</p>
        </div>
        <template v-if="payFee != null && payFee != undefined && payFee != ''">
          <div class="col-12">
            <p class="card card-inner-title mb-2 ml-2">{{payFeeTitle}}</p>
            <p class="card card-inner-text mb-1 ml-2">{{payFee}}</p>
          </div>
        </template>
        <template v-if="purpose && paymentForm != 'TEF'">
          <div class="col-12">
            <p class="card card-inner-title mb-2 ml-2">{{purposeTitle}}</p>
            <p class="card card-inner-text mb-1 ml-2">{{purpose}}</p>
          </div>
        </template>
        <div class="col-12">
          <p class="card card-inner-title mb-2 ml-2">{{consentIDTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{consentID}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, onMounted } from 'vue';
type CalendarValueType = Date | Date[] | undefined;

export default defineComponent({
  name: 'ConsentStatusCardDetail',
  props: {
    id: Number,
    detailView: String,
    cardStatus: {
      type: String,
      required: true
    },
    cardStatusTitle: String,
    cardStatusDescription: String,
    cardInfoTitle: String,
    cardInfoDescription: String,
    initiatorTitle: String,
    initiatorName: String,
    payerDetailTitle: String,
    payerCpf: String,
    payerName: String,
    payerBranch: String,
    payerCc: String,
    localInstrument: String,
    beneficiaryDetailTitle: String,
    beneficiaryCnpj: String,
    beneficiaryName: String,
    beneficiaryPixLabel: String,
    beneficiaryPixValue: String,
    beneficiaryBankLabel: String,
    beneficiaryBankCode: String,
    beneficiaryAggLabel: String,
    beneficiaryAgg: String,
    beneficiaryAccount: String,
    beneficiaryQRDinLabel: String,
    beneficiaryQREstLabel: String,
    consentCreatorLabel: String,
    consentCreatorName: String,
    consentRejectionReasonCodeLabel: String,
    consentRejectionReasonCode: String,
    creationDateTitle: String,
    initiationDateTitle: String,
    scheduledDateTitle: String,
    revocationReasonTitle: String,
    revocationReason: String,
    creationDate: String,
    initiationDate: String,
    scheduledDate: String,
    valueTitle: String,
    value: String,
    paymentFormTitle: String,
    paymentForm: String,
    consentIDTitle: String,
    consentID: String,
    haveDebtorAccount: Boolean,
    payFeeTitle: String,
    payFee: String,
    purposeTitle: String,
    purpose: String,
    typeScheduleV4: String,
    v4NameWeek: String,
    v4DayMonth: Number,
    recurrence: Number,
    scheduledDatev4: {
      type: Object as PropType<CalendarValueType>
    },
    customMsgInfo: String,
    recurringConfig: Object
  },

  data() {
    return {
      customCalendar: false,
      customButton: {
        'font-style': 'normal',
        'font-weight': '700',
        'font-size': 'var(--button-confirmconsent-text-size)',
        'padding-top': '16px',
        'padding-bottom': '16px'
      }
    };
  },

  computed: {
    scheduledDatev4Local: {
      get: function() : CalendarValueType {
        if (Array.isArray(this.scheduledDatev4)) {
          return this.scheduledDatev4.map(date => date instanceof Date ? date : new Date(date));
        } else if (this.scheduledDatev4 instanceof Date) {
          return this.scheduledDatev4;
        } else {
          return undefined;
        }
      },
      set: function() : void {
        console.warn('não permitido');
      }
    }
  },

  methods: {
    test(message: string) : void {
      console.log(message);
    },
    getCssClassBox(status: string) : string {
      return 'grid box box-' + status;
    },
    getCssClass(status: string) : string {
      return 'col-12 card-header card-header-' + status;
    },
    getCssClassToggler(status: string) : string {
      return 'm-auto toggler toggler-' + status;
    },
    getCssClassDescriptionContainer(status: string) : string {
      return 'card-status-description-container card-status-description-container-' + status;
    },
    getCssDebtorData(haveDebtorData: boolean) : string {
      return haveDebtorData ? 'display: block' : 'display: none';
    },
    goToView(viewName: string, params?: Record<string, string | number | (string | number)[] | null | undefined>) : void {
      this.$router.push({ name: viewName, params});
    },

    typeRecurrence () : string {
      switch (this.typeScheduleV4) {
        case 'daily':
          return 'diário';
        case 'weekly':
          return 'semanal';
        case 'monthly':
          return 'mensal';
        case 'custom':
          return 'custom';
        default: return 'não definido';
      }
    },

    formatDayWeek () : string {
      switch (this.v4NameWeek) {
        case 'SEGUNDA_FEIRA':
          return 'segunda-feira';
        case 'TERCA_FEIRA':
          return 'terça-feira';
        case 'QUARTA_FEIRA':
          return 'quarta-feira';
        case 'QUINTA_FEIRA':
          return 'quinta-feira';
        case 'SEXTA_FEIRA':
          return 'sexta-feira';
        case 'SABADO':
          return 'sábado';
        case 'DOMINGO':
          return 'domingo';
        default: return 'não definido';
      }
    }
  }
});
</script>

<style scoped>

.box {
  background-color: #FFFFFF;
  box-shadow: var(--shadow-default);
  border-radius: 8px;
  margin: 1em
}
.box-waiting {
  background-color: var(--consultconsent-awaiting-background-color);
}
.box-consumed {
  background-color: var(--consultconsent-consumed-background-color);
}
.box-revoked {
  background-color: var(--consultconsent-revoked-background-color);
}
.box-authorised {
  background-color: var(--consultconsent-authorised-background-color);
}
.box-expired {
  background-color: var(--consultconsent-expired-background-color);
}
.box-rejected {
  background-color: var(--consultconsent-rejected-background-color);
}

.card {
  font-style: normal;
  display: flex;
  align-items: center;
}
.card-header {
  min-height: 74px;
  min-width: 312px;
  border-radius: 8px 8px 0px 0px;
  padding: 0.5em;
}

.toggler {
  border-radius: 8px;
  min-height:6px;
  width:37px;
}

.card-header-waiting {
  background: var(--consultconsent-awaiting-background-color);
}
.toggler-waiting {
  background: var(--consultconsent-awaiting-background-color);
}

.card-header-consumed {
  background: var(--consultconsent-consumed-background-color);
}
.toggler-consumed {
  background: var(--consultconsent-consumed-background-color);
}

.card-header-revoked {
  background: var(--consultconsent-revoked-background-color);
}
.toggler-revoked {
  background: var(--consultconsent-revoked-background-color);
}

.card-header-authorised {
  background: var(--consultconsent-authorised-background-color);
}
.toggler-authorised {
  background: var(--consultconsent-authorised-background-color);
}

.card-header-expired {
  background: var(--consultconsent-expired-background-color);
}
.toggler-expired {
  background: var(--consultconsent-expired-background-color);
}

.card-header-rejected {
  background: var(--consultconsent-rejected-background-color);
}
.toggler-rejected {
  background: var(--consultconsent-rejected-background-color);
}

.card-header-waiting .card-status {
  color: var(--consultconsent-awaiting-text-color);
}
.card-header-rejected .card-status {
  color: var(--consultconsent-rejected-text-color);
}
.card-header-consumed .card-status {
  color: var(--consultconsent-consumed-text-color);
}
.card-header-revoked .card-status {
  color: var(--consultconsent-revoked-text-color);
}
.card-header-authorised .card-status {
  color: var(--consultconsent-authorised-text-color);
}
.card-header-expired .card-status {
  color: var(--consultconsent-expired-text-color);
}
.card-status {
  font-weight: bold;
}
.card-status-title {
  font-size: 16px;
  line-height: 22px;
}

.card-status-description-container {
  border-radius: 8px;
  background-color: var(--page-background-color);
  width: 100%;
}
.card-status-description-container-waiting {
  border: 1px solid var(--consultconsent-awaiting-background-color);
}
.card-status-description-container-consumed {
  border: 1px solid var(--consultconsent-consumed-background-color);
}
.card-status-description-container-revoked {
  border: 1px solid var(--consultconsent-revoked-background-color);
}
.card-status-description-container-authorised {
  border: 1px solid var(--consultconsent-authorised-background-color);
}
.card-status-description-container-expired {
  border: 1px solid var(--consultconsent-expired-background-color);
}
.card-status-description-container-rejected {
  border: 1px solid var(--consultconsent-rejected-background-color);
}

.card-status-description {
  font-size: 12px;
  line-height: 12px;
}
.card-inner-title {
  color: var(--consultconsent-box-down-inner-title-color);
  font-weight: var(--consultconsent-box-down-inner-title-weight);
  font-size: 14px;
  line-height: 16px;
}
.card-inner-text {
  color: var(--consultconsent-box-down-text-color);
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin:0;
  word-break: break-all;
}

.card-info-title {
  color: var(--consultconsent-box-down-title-color);
  font-weight: var(--consultconsent-box-down-title-weight);
  font-size: 20px;
  line-height: 24px;
}

.card-info-description {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}

.font-14-click {
  font-size: 14px !important;
  cursor: pointer;
}
</style>