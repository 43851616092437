<template>
  <div id="imageFlexContainer" class="flex justify-content-center maradjust" v-if="align == 'center'">
    <img v-if="themeLogo === 'amz' || themeLogo === 'exb'" alt="logo" src="@/assets/logo-open-finance.png" height="40">
    <img v-else alt="logo" src="@/assets/logo-open-finance.png" height="40">
  </div>
  <div id="imageFlexContainer" class="flex justify-content-left maradjust" v-if="align == 'left'">
    <img v-if="themeLogo === 'amz' || themeLogo === 'exb'" alt="logo" src="@/assets/logo-open-finance.png" height="40">
    <img v-else alt="logo" src="@/assets/logo-open-finance.png" height="40">
  </div>
  <div id="imageFlexContainer" class="flex justify-content-center" v-if="align == 'tecban-center'">
    <img alt="logo" src="@/assets/logo-tecban-full.png" width="225">
  </div>
  <div id="imageFlexContainer" class="flex justify-content-left" v-if="align == 'tecban-left'">
    <img alt="logo" src="@/assets/logo-tecban-full.png" width="225">
  </div>
  <div id="imageFlexContainer" class="" v-if="align == 'open-banking-center'">
    <img alt="logo" src="@/assets/logo-open-finance.png" width="225">
  </div>
  <div id="imageFlexContainer" class="flex justify-content-left maradjust" v-if="align == 'open-banking-left'">
    <img v-if="themeLogo === 'amz' || themeLogo === 'exb'" alt="logo" src="@/assets/logo-open-finance.png">
    <img v-else alt="logo" src="@/assets/logo-open-finance.png">
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getTheme } from '@/util/util.js';

export default defineComponent({
  name: 'ImageFlexContainer',
  props: {
    align: String
  },
  data(){
    return{
      themeLogo: ''
    };
  },
  created(){
    this.themeLogo = getTheme();
  }
});





</script>

<style scoped>

img {
  display: inline;
}
.maradjust{
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>