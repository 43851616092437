<template>
  <div class="mb-2">
  
    <div v-bind:class="getCssClassBox(cardStatus)">
      <div v-bind:class="getCssClass(cardStatus)">
        <p class="card card-status card-status-title mb-2 ml-2">{{cardStatusTitle}}</p>
        <p class="card card-status card-status-description ml-2">{{cardStatusDescription}}</p>
      </div>

      <div class="row" v-bind:class="getCssClassDescriptionContainer(cardStatus)">
        <div class="pt-2">
          <div v-bind:class="getCssClassToggler(cardStatus)" ></div>
        </div>
        <div class="col-12">
          <p class="card card-info-title mb-1 ml-2">{{cardInfoTitle}}</p>
        </div>

        <div class="col-12" v-if="isAutomaticPayment && jwe?.accounts[0]">
          <p class="card card-inner-title mb-2 ml-2">Dados Pagador</p>
          <p class="card card-inner-text mb-1 ml-2" v-if="isAutomaticPayment.contractDebtor?.document?.rel">
            {{isAutomaticPayment.contractDebtor?.document?.rel}}: {{isAutomaticPayment.contractDebtor?.document?.identification}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{ jwe?.accounts[0]?.owner }}</p>
        </div>
  
        <div class="col-12" v-bind:style="getCssDebtorData(haveDebtorAccount)">
          <p class="card card-inner-title mb-2 ml-2">{{isAutomaticPayment ? 'Dados do recebedor' : payerDetailTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{payerCpf}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{beneficiaryName}}</p>
          <p v-if="initiatorName" class="card card-inner-text mb-1 ml-2">{{initiatorName}}</p>
        </div>

        <div class="col-12" v-if="initiatorAccount">
          <p class="card card-inner-title mb-2 ml-2">{{initiatorTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{initiatorBank}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{initiatorAccount}}</p>
        </div>

        <div class="col-12" v-if="isSweeping">
          <p class="card card-inner-title mb-2 ml-2">Descrição da autorização</p>
          <p class="card card-inner-text mb-1 ml-2">Gestão Financeira Automatizada</p>
        </div>

        <div class="col-12" v-if="isAutomaticPayment?.contractId">
          <p class="card card-inner-title mb-2 ml-2">Número do contrato</p>
          <p class="card card-inner-text mb-1 ml-2">{{ isAutomaticPayment?.contractId }}</p>
        </div>

        <div class="col-12" v-if="additionalInformationAutomaticPayment">
          <p class="card card-inner-title mb-2 ml-2">Referente a:</p>
          <p class="card card-inner-text mb-1 ml-2">{{ additionalInformationAutomaticPayment }}</p>
        </div>

        <div class="col-12" v-if="isAutomaticPayment">
          <p class="card card-inner-title mb-2 ml-2">Recorrência do pagamento ({{ isAutomaticPayment.interval.toLowerCase() }})</p>
          <p class="card card-inner-text mb-1 ml-2">{{ automaticPaymentIntervalLabel(isAutomaticPayment.interval, isAutomaticPayment.firstPayment.date) }}</p>
        </div>

        <div class="col-12" v-if="isAutomaticPayment?.fixedAmount">
          <p class="card card-inner-title mb-2 ml-2">Valor dos pagamentos</p>
          <p class="card card-inner-text mb-1 ml-2">{{ formatValueToReal(isAutomaticPayment.fixedAmount) }}</p>
        </div>

        <div class="col-12" v-if="isAutomaticPayment?.minimumVariableAmount">
          <p class="card card-inner-title mb-2 ml-2">Limites transacionais</p>
          <p class="card card-inner-text mb-1 ml-2">Mínimo {{ formatValueToReal(isAutomaticPayment.minimumVariableAmount) }} {{ periodLabel(isAutomaticPayment.interval) }} e</p>
          <p class="card card-inner-text mb-1 ml-2">Máximo {{ formatValueToReal(isAutomaticPayment.maximumVariableAmount) }} {{ periodLabel(isAutomaticPayment.interval) }}</p>
        </div>
        
        <div class="col-12" v-if="isAutomaticPayment?.firstPayment?.date">
          <p class="card card-inner-title mb-2 ml-2">Data do primeiro pagamento</p>
          <p class="card card-inner-text mb-1 ml-2">{{ convertUtcDateToString(isAutomaticPayment.firstPayment.date, 'pt-BR', false) }}</p>
        </div>

        <div class="col-12" v-if="isAutomaticPayment">
          <p class="card card-inner-title mb-2 ml-2">Data de expiração da autorização</p>
          <p class="card card-inner-text mb-1 ml-2">{{ expiryDate }}</p>
        </div>
        
        <div class="col-12" v-if="!isAutomaticPayment">
          <p class="card card-inner-title mb-2 ml-2">Tipo de transação</p>
          <p class="card card-inner-text mb-1 ml-2">PIX</p>
        </div>

        <div class="col-12" v-else>
          <p class="card card-inner-title mb-2 ml-2">Forma de pagamento</p>
          <p class="card card-inner-text mb-1 ml-2">Pix Automático</p>
        </div>

        <!-- Caso o usuário selecione algum dos campos abaixo de periodicidade e limites -->
        <div v-if="!!selectedPeriodicTotal" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Limite de todas as tranferências</p>
          <p class="card card-inner-text mb-1 ml-2">{{selectedPeriodicTotal}}</p>
        </div>

        <div v-if="selectedPeriodicTransation" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Limite por transação</p>
          <p class="card card-inner-text mb-1 ml-2">{{selectedPeriodicTransation}}</p>
        </div>

        <div v-if="selectedPeriodicLimitDay" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Limite diário</p>
          <p class="card card-inner-text mb-1 ml-2">{{selectedPeriodicLimitDay}}</p>
        </div>
        <div v-if="selectedPeriodicQuantityDay" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Quantidade de pagamentos diários</p>
          <p class="card card-inner-text mb-1 ml-2">{{selectedPeriodicQuantityDay}}</p>
        </div>

        <div v-if="selectedPeriodicLimitWeek" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Limite semanal</p>
          <p class="card card-inner-text mb-1 ml-2">{{selectedPeriodicLimitWeek}}</p>
        </div>

        <div v-if="selectedPeriodicQuantityWeek" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Quantidade de pagamentos semanais</p>
          <p class="card card-inner-text mb-1 ml-2">{{selectedPeriodicQuantityWeek}}</p>
        </div>
        
        <div v-if="selectedPeriodicLimitMonth" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Limite mensal</p>
          <p class="card card-inner-text mb-1 ml-2">{{selectedPeriodicLimitMonth}}</p>
        </div>
        <div v-if="selectedPeriodicQuantityMonth" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Quantidade de pagamentos mensais</p>
          <p class="card card-inner-text mb-1 ml-2">{{selectedPeriodicQuantityMonth}}</p>
        </div>

        <div v-if="selectedPeriodicLimitYear" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Limite anual</p>
          <p class="card card-inner-text mb-1 ml-2">{{selectedPeriodicLimitYear}}</p>
        </div>
        <div v-if="selectedPeriodicQuantityYear" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Quantidade de pagamentos anuais</p>
          <p class="card card-inner-text mb-1 ml-2">{{selectedPeriodicQuantityYear}}</p>
        </div>

        <div class="col-12" v-if="!isAutomaticPayment">
          <p class="card card-inner-title mb-2 ml-2">{{creationDateTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{creationDate}}</p>
        </div>
        <div class="col-12" v-if="!isAutomaticPayment">
          <p class="card card-inner-title mb-2 ml-2">{{expiryDateTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{expiryDate}}</p>
        </div>
        <!-- FIM -->
        
        <!--<div class="col-12" v-if="consentRejectionReasonCode">
          <p class="card card-inner-title mb-2 ml-2">{{consentRejectionReasonCodeLabel}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{consentRejectionReasonCode}}</p>
        </div>

        <div v-if="scheduledDate && cardStatus == 'revoked' && revocationReason !== undefined" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">{{revocationReasonTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{revocationReason}}</p>
        </div>
        <div v-if="scheduledDate" class="col-12">
          <p class="card card-inner-title mb-2 ml-2">{{scheduledDateTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{scheduledDate}}</p>
        </div>-->
        <div class="col-12" v-if="originBankName">
          <p class="card card-inner-title mb-2 ml-2">{{originLabel}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{originBankName}}</p>
        </div>
        <div class="col-12" v-if='payFee'>
          <p class="card card-inner-title mb-2 ml-2">{{payFeeTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{payFee}}</p>
        </div>

        <div class="col-12" v-if="isAutomaticPayment">
          <p class="card card-inner-title mb-2 ml-2">Iniciador das transferências</p>
          <p class="card card-inner-text mb-1 ml-2">{{tppStart}}</p>
        </div>

        <div class="col-12">
          <p class="card card-inner-title mb-2 ml-2">{{consentIDTitle}}</p>
          <p class="card card-inner-text mb-1 ml-2">{{consentID}}</p>
        </div>
      </div>

    </div>

    <div
      v-if="cardStatus === 'authorised' && isAutomaticPayment?.fixedAmount"
      v-bind:class="getCssClassBox(cardStatus)"
    >
      <div v-bind:class="getCssClass(cardStatus)">
        <p class="card card-status card-status-title  ml-2">Pagamento imediato</p>
      </div>


      <div class="row" v-bind:class="getCssClassDescriptionContainer(cardStatus)">
        <div class="pt-2">
          <div v-bind:class="getCssClassToggler(cardStatus)" ></div>
        </div>

        <div class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Referente a:</p>
          <p class="card card-inner-text mb-1 ml-2">{{isAutomaticPayment?.firstPayment?.remittanceInformation}}</p>
        </div>

        <div class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Valor: </p>
          <p class="card card-inner-text mb-1 ml-2">{{  formatValueToReal(isAutomaticPayment?.firstPayment?.amount) }}</p>
        </div>

        <div class="col-12">
          <p class="card card-inner-title mb-2 ml-2">Data do pagamento</p>
          <p class="card card-inner-text mb-1 ml-2">Imediato</p>
        </div>
      </div>

    </div>

  </div>
</template>

<script lang="ts">
import {  defineComponent } from 'vue';
export default defineComponent({
  name: 'ConsentStatusCardDetailSameUser',
  props: {
    id: Number,
    detailView: String,
    cardStatus: {
      type: String,
      required: true
    },
    isConsent: Boolean,
    cardStatusTitle: String,
    cardStatusDescription: String,
    cardInfoTitle: String,
    cardInfoDescription: String,
    initiatorTitle: String,
    initiatorName: String,
    initiatorBank: String,
    initiatorAccount: String,
    payerDetailTitle: String,
    payerCpf: String,
    payerName: String,
    payerBranch: String,
    payerCc: String,
    localInstrument: String,
    originLabel: String,
    beneficiaryName: String,
    beneficiaryPixLabel: String,
    beneficiaryPixValue: String,
    originBankLabel: String,
    originBankName: String,
    originAccountLabel: String,
    originAccountNumber: String,
    selectedPeriodicTotal: String,
    selectedPeriodicTransation: String,
    selectedPeriodicLimitDay: String,
    selectedPeriodicQuantityDay: String,
    selectedPeriodicLimitWeek: String,
    selectedPeriodicQuantityWeek: String,
    selectedPeriodicLimitMonth: String,
    selectedPeriodicQuantityMonth: String,
    selectedPeriodicLimitYear: String,
    selectedPeriodicQuantityYear: String,
    beneficiaryAccount: String,
    beneficiaryQRDinLabel: String,
    beneficiaryQREstLabel: String,
    consentCreatorLabel: String,
    consentCreatorName: String,
    consentRejectionReasonCodeLabel: String,
    consentRejectionReasonCode: String,
    creationDateTitle: String,
    creationDate: String,
    expiryDateTitle: String,
    expiryDate: String,
    initiationDateTitle: String,
    scheduledDateTitle: String,
    revocationReasonTitle: String,
    revocationReason: String,
    initiationDate: String,
    scheduledDate: String,
    valueTitle: String,
    value: String,
    paymentFormTitle: String,
    paymentForm: String,
    consentIDTitle: String,
    consentID: String,
    haveDebtorAccount: Boolean,
    payFeeTitle: String,
    payFee: String,
    purposeTitle: String,
    purpose: String,
    typeScheduleV4: String,
    v4NameWeek: String,
    v4DayMonth: Number,
    recurrence: Number,
    customMsgInfo: String,
    isSweeping: Boolean,
    isAutomaticPayment: Object,
    additionalInformationAutomaticPayment: String,
    tppStart: String,
    jwe: Object
  },

  methods: {
    test(message: string) : void {
      console.log(message);
    },
    getCssClassBox(status: string) : string {
      return 'grid box box-' + status;
    },
    getCssClass(status: string) : string {
      return 'col-12 card-header card-header-' + status;
    },
    getCssClassToggler(status: string) : string {
      return 'm-auto toggler toggler-' + status;
    },
    getCssClassDescriptionContainer(status: string) : string {
      return 'card-status-description-container card-status-description-container-' + status;
    },
    getCssDebtorData(haveDebtorData: boolean) : string {
      return haveDebtorData ? 'display: block' : 'display: none';
    },
    goToView(viewName: string, params?: Record<string, string | number | (string | number)[] | null | undefined>) : void {
      this.$router.push({ name: viewName, params});
    },

    typeRecurrence () : string {
      switch (this.typeScheduleV4) {
        case 'daily':
          return 'diário';
        case 'weekly':
          return 'semanal';
        case 'monthly':
          return 'mensal';
        case 'custom':
          return 'custom';
          default: return 'não definido';
      }
    },

    formatDayWeek () : string {
      switch (this.v4NameWeek) {
        case 'SEGUNDA_FEIRA':
          return 'segunda-feira';
        case 'TERCA_FEIRA':
          return 'terça-feira';
        case 'QUARTA_FEIRA':
          return 'quarta-feira';
        case 'QUINTA_FEIRA':
          return 'quinta-feira';
        case 'SEXTA_FEIRA':
          return 'sexta-feira';
        case 'SABADO':
          return 'sábado';
        case 'DOMINGO':
          return 'domingo';
        default: return 'não definido';
      }
    },

    periodLabel (interval) {
      switch (interval) {
        case 'SEMANAL':
          return 'à semana.';
        case 'MENSAL':
          return 'ao mês';
        case 'ANUAL':
          return 'ao ano';
        case 'TRIMESTRAL':
          return 'ao trimestre';
        case 'SEMESTRAL':
          return 'ao semestre';
      }
    },

    automaticPaymentIntervalLabel (interval: string, date: string): string|undefined {
      const [year, month, day] = date.split('-');
      const objDate = new Date(date);
      const dayOfWeek = objDate.getDay();

      const diaDaSemana = ['Domingo', 'Segunda-feira','Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sabado'];

      switch (interval) {
        case 'SEMANAL':
          return `Toda(o) ${diaDaSemana[dayOfWeek]}.`;
        case 'MENSAL':
          return `Todo dia ${day} do mês`;
        case 'ANUAL':
          return `Todo ${day}/${month} do ano`;
        case 'SEMESTRAL':
          return `todo dia ${day} a cada 6 mesês`;
        case 'TRIMESTRAL':
          return `todo dia ${day} a cada 3 mesês`;
      }
    },

    convertUtcDateToString (date: string, locale, isDateTime) {
      const tmp = new Date(date);
      const newDate = this.addHours(tmp, 3);
      return isDateTime ? newDate.toLocaleString(locale) : newDate.toLocaleDateString(locale);
    },

    addHours (date, hours) {
      const newDate = new Date(date);
      newDate.setHours(newDate.getHours() + hours);
      return newDate;
    },

    formatValueToReal (value) {
      const formCurrency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 4
      });
      return formCurrency.format(value);
    }
  }
});
</script>

<style scoped>

.box {
  background-color: #FFFFFF;
  box-shadow: var(--shadow-default);
  border-radius: 8px;
  margin: 1em
}
.box-waiting {
  background-color: var(--consultconsent-awaiting-background-color);
}
.box-consumed {
  background-color: var(--consultconsent-consumed-background-color);
}
.box-revoked {
  background-color: var(--consultconsent-revoked-background-color);
}
.box-authorised {
  background-color: var(--consultconsent-authorised-background-color);
}
.box-expired {
  background-color: var(--consultconsent-expired-background-color);
}
.box-rejected {
  background-color: var(--consultconsent-rejected-background-color);
}

.card {
  font-style: normal;
  display: flex;
  align-items: center;
}
.card-header {
  min-height: 74px;
  min-width: 312px;
  border-radius: 8px 8px 0px 0px;
  padding: 0.5em;
}

.toggler {
  border-radius: 8px;
  min-height:6px;
  width:37px;
}

.card-header-waiting {
  background: var(--consultconsent-awaiting-background-color);
}
.toggler-waiting {
  background: var(--consultconsent-awaiting-background-color);
}

.card-header-consumed {
  background: var(--consultconsent-consumed-background-color);
}
.toggler-consumed {
  background: var(--consultconsent-consumed-background-color);
}

.card-header-revoked {
  background: var(--consultconsent-revoked-background-color);
}
.toggler-revoked {
  background: var(--consultconsent-revoked-background-color);
}

.card-header-authorised {
  background: var(--consultconsent-authorised-background-color);
}
.toggler-authorised {
  background: var(--consultconsent-authorised-background-color);
}

.card-header-expired {
  background: var(--consultconsent-expired-background-color);
}
.toggler-expired {
  background: var(--consultconsent-expired-background-color);
}

.card-header-rejected {
  background: var(--consultconsent-rejected-background-color);
}
.toggler-rejected {
  background: var(--consultconsent-rejected-background-color);
}

.card-header-waiting .card-status {
  color: var(--consultconsent-awaiting-text-color);
}
.card-header-rejected .card-status {
  color: var(--consultconsent-rejected-text-color);
}
.card-header-consumed .card-status {
  color: var(--consultconsent-consumed-text-color);
}
.card-header-revoked .card-status {
  color: var(--consultconsent-revoked-text-color);
}
.card-header-authorised .card-status {
  color: var(--consultconsent-authorised-text-color);
}
.card-header-expired .card-status {
  color: var(--consultconsent-expired-text-color);
}
.card-status {
  font-weight: bold;
}
.card-status-title {
  font-size: 16px;
  line-height: 22px;
}

.card-status-description-container {
  border-radius: 8px;
  background-color: var(--page-background-color);
  width: 100%;
}
.card-status-description-container-waiting {
  border: 1px solid var(--consultconsent-awaiting-background-color);
}
.card-status-description-container-consumed {
  border: 1px solid var(--consultconsent-consumed-background-color);
}
.card-status-description-container-revoked {
  border: 1px solid var(--consultconsent-revoked-background-color);
}
.card-status-description-container-authorised {
  border: 1px solid var(--consultconsent-authorised-background-color);
}
.card-status-description-container-expired {
  border: 1px solid var(--consultconsent-expired-background-color);
}
.card-status-description-container-rejected {
  border: 1px solid var(--consultconsent-rejected-background-color);
}

.card-status-description {
  font-size: 12px;
  line-height: 12px;
}
.card-inner-title {
  color: var(--consultconsent-box-down-inner-title-color);
  font-weight: var(--consultconsent-box-down-inner-title-weight);
  font-size: 14px;
  line-height: 16px;
}
.card-inner-text {
  color: var(--consultconsent-box-down-text-color);
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin:0;
  word-break: break-all;
}

.card-info-title {
  color: var(--consultconsent-box-down-title-color);
  font-weight: var(--consultconsent-box-down-title-weight);
  font-size: 20px;
  line-height: 24px;
}

.card-info-description {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}

.font-14-click {
  font-size: 14px !important;
  cursor: pointer;
}

</style>