import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "card card-status card-status-title mb-2 ml-2" }
const _hoisted_3 = { class: "card card-status card-status-description ml-2" }
const _hoisted_4 = { class: "pt-2" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "card card-info-title mb-1 ml-2" }
const _hoisted_7 = {
  key: 0,
  class: "col-12"
}
const _hoisted_8 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_9 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_12 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_13 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_14 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_15 = {
  key: 0,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_16 = {
  key: 1,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_17 = {
  key: 2,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_18 = { class: "col-12" }
const _hoisted_19 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_20 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_21 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_22 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_23 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_24 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_25 = {
  key: 1,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_26 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_27 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_28 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_29 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_30 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_31 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_32 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_33 = {
  key: 1,
  class: "col-12"
}
const _hoisted_34 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_35 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_36 = {
  key: 2,
  class: "col-12"
}
const _hoisted_37 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_38 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_39 = {
  key: 3,
  class: "col-12"
}
const _hoisted_40 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_41 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_42 = {
  key: 4,
  class: "col-12"
}
const _hoisted_43 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_44 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_45 = {
  key: 5,
  class: "col-12"
}
const _hoisted_46 = {
  key: 0,
  class: "card card-inner-title mb-2 ml-2"
}
const _hoisted_47 = {
  key: 1,
  class: "card card-inner-title mb-2 ml-2"
}
const _hoisted_48 = {
  key: 2,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_49 = {
  key: 6,
  class: "col-12"
}
const _hoisted_50 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_51 = {
  key: 7,
  class: "col-12"
}
const _hoisted_52 = {
  key: 0,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_53 = {
  key: 1,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_54 = {
  key: 8,
  class: "col-12"
}
const _hoisted_55 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_56 = {
  key: 9,
  class: "col-12"
}
const _hoisted_57 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_58 = {
  key: 0,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_59 = {
  key: 1,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_60 = {
  key: 2,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_61 = {
  key: 10,
  class: "col-12"
}
const _hoisted_62 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_63 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_64 = { class: "col-12" }
const _hoisted_65 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_66 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_67 = {
  key: 11,
  class: "col-12"
}
const _hoisted_68 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_69 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_70 = {
  key: 12,
  class: "col-12"
}
const _hoisted_71 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_72 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_73 = { class: "col-12" }
const _hoisted_74 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_75 = { class: "card card-inner-text mb-1 ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Cbutton = _resolveComponent("Cbutton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      visible: _ctx.customCalendar,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customCalendar) = $event)),
      style: {"height":"auto","max-height":"100vh"},
      modal: "",
      header: "Veja todas as datas escolhidas"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Calendar, {
          id: "calendar-exibicao",
          modelValue: _ctx.scheduledDatev4Local,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.scheduledDatev4Local) = $event)),
          inline: "",
          selectionMode: "multiple"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_Cbutton, {
          rotulo: "Voltar",
          tipo: "button",
          confirmacao: false,
          onBotaoAtivado: _cache[1] || (_cache[1] = ($event: any) => (_ctx.customCalendar = false))
        })
      ]),
      _: 1
    }, 8, ["visible"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.getCssClassBox(_ctx.cardStatus))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.getCssClass(_ctx.cardStatus))
        }, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.cardStatusTitle), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.cardStatusDescription), 1)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["row", _ctx.getCssClassDescriptionContainer(_ctx.cardStatus)])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.getCssClassToggler(_ctx.cardStatus))
            }, null, 2)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.cardInfoTitle), 1)
          ]),
          (_ctx.initiatorName != null && _ctx.initiatorName != undefined && _ctx.initiatorName != '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.initiatorTitle), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.initiatorName), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.creationDateTitle), 1),
            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.creationDate), 1)
          ]),
          _createElementVNode("div", {
            class: "col-12",
            style: _normalizeStyle(_ctx.getCssDebtorData(_ctx.haveDebtorAccount))
          }, [
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.payerDetailTitle), 1),
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.payerCpf), 1),
            (_ctx.payerName != null && _ctx.payerName != undefined && _ctx.payerName != '')
              ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.payerName), 1))
              : _createCommentVNode("", true),
            (_ctx.payerBranch != null && _ctx.payerBranch != undefined && _ctx.payerBranch != '')
              ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.payerBranch), 1))
              : _createCommentVNode("", true),
            (_ctx.payerCc != null && _ctx.payerCc != undefined && _ctx.payerCc != '')
              ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.payerCc), 1))
              : _createCommentVNode("", true)
          ], 4),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.beneficiaryDetailTitle), 1),
            _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.beneficiaryCnpj), 1),
            _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.beneficiaryName), 1),
            (_ctx.localInstrument == 'MANU')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.beneficiaryBankLabel) + ": " + _toDisplayString(_ctx.beneficiaryBankCode), 1),
                  _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.beneficiaryAggLabel) + " " + _toDisplayString(_ctx.beneficiaryAgg), 1),
                  _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.beneficiaryAccount), 1)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.localInstrument == 'DICT' || _ctx.localInstrument == 'INIC')
              ? (_openBlock(), _createElementBlock("p", _hoisted_25, _toDisplayString(_ctx.beneficiaryPixLabel) + " " + _toDisplayString(_ctx.beneficiaryPixValue), 1))
              : _createCommentVNode("", true),
            (_ctx.localInstrument == 'QRDN')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.beneficiaryQRDinLabel), 1),
                  _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.beneficiaryPixLabel) + " " + _toDisplayString(_ctx.beneficiaryPixValue), 1)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.localInstrument == 'QRES')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.beneficiaryQREstLabel), 1),
                  _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.beneficiaryPixLabel) + " " + _toDisplayString(_ctx.beneficiaryPixValue), 1)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.paymentForm === 'TED' || _ctx.paymentForm === 'TEF')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                  _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.beneficiaryBankLabel) + " " + _toDisplayString(_ctx.beneficiaryBankCode), 1),
                  _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.beneficiaryAggLabel) + " " + _toDisplayString(_ctx.beneficiaryAgg), 1),
                  _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.beneficiaryAccount), 1)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          (_ctx.consentRejectionReasonCode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.consentRejectionReasonCodeLabel), 1),
                _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.consentRejectionReasonCode), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.initiationDate)
            ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.initiationDateTitle), 1),
                _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.initiationDate), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.scheduledDate && _ctx.cardStatus == 'revoked' && _ctx.revocationReason !== undefined)
            ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.revocationReasonTitle), 1),
                _createElementVNode("p", _hoisted_41, _toDisplayString(_ctx.revocationReason), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.scheduledDate)
            ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                _createElementVNode("p", _hoisted_43, _toDisplayString(_ctx.scheduledDateTitle), 1),
                _createElementVNode("p", _hoisted_44, _toDisplayString(_ctx.scheduledDate), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.scheduledDatev4)
            ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                (_ctx.typeScheduleV4 !== 'custom')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_46, "Data do primeiro pagamento"))
                  : (_openBlock(), _createElementBlock("p", _hoisted_47, "Data dos pagamentos")),
                (_ctx.typeScheduleV4 !== 'custom')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_48, _toDisplayString(_ctx.scheduledDatev4), 1))
                  : (_openBlock(), _createElementBlock("p", {
                      key: 3,
                      class: "card card-inner-text mb-1 ml-2 font-14-click",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.customCalendar = true))
                    }, _cache[4] || (_cache[4] = [
                      _createElementVNode("strong", null, "Ver todas as datas", -1)
                    ])))
              ]))
            : _createCommentVNode("", true),
          (_ctx.customMsgInfo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                _cache[5] || (_cache[5] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Mensagem adicional", -1)),
                _createElementVNode("p", _hoisted_50, _toDisplayString(_ctx.customMsgInfo), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.scheduledDatev4 && _ctx.typeRecurrence() === 'semanal')
            ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                _cache[6] || (_cache[6] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Dia da semana do pagamento", -1)),
                (_ctx.v4NameWeek === 'SABADO' || _ctx.v4NameWeek === 'DOMINGO')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_52, "Todo " + _toDisplayString(_ctx.formatDayWeek()) + " da semana", 1))
                  : (_openBlock(), _createElementBlock("p", _hoisted_53, "Toda " + _toDisplayString(_ctx.formatDayWeek()) + " da semana", 1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.scheduledDatev4 && _ctx.typeRecurrence() === 'mensal')
            ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                _cache[7] || (_cache[7] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Dia do mês do pagamento", -1)),
                _createElementVNode("p", _hoisted_55, "Todo dia " + _toDisplayString(_ctx.v4DayMonth) + " do mês", 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.recurrence)
            ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                _createElementVNode("p", _hoisted_57, "Recorrência do pagamento (" + _toDisplayString(_ctx.typeRecurrence()) + ")", 1),
                (_ctx.typeRecurrence() === 'diário')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_58, _toDisplayString(_ctx.recurrence) + " dias seguidos", 1))
                  : _createCommentVNode("", true),
                (_ctx.typeRecurrence() === 'semanal')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_59, _toDisplayString(_ctx.recurrence) + " semanas", 1))
                  : _createCommentVNode("", true),
                (_ctx.typeRecurrence() === 'mensal')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_60, _toDisplayString(_ctx.recurrence) + " meses", 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                _createElementVNode("p", _hoisted_62, _toDisplayString(_ctx.valueTitle), 1),
                _createElementVNode("p", _hoisted_63, _toDisplayString(_ctx.value), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_64, [
            _createElementVNode("p", _hoisted_65, _toDisplayString(_ctx.paymentFormTitle), 1),
            _createElementVNode("p", _hoisted_66, _toDisplayString(_ctx.paymentForm), 1)
          ]),
          (_ctx.payFee != null && _ctx.payFee != undefined && _ctx.payFee != '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                _createElementVNode("p", _hoisted_68, _toDisplayString(_ctx.payFeeTitle), 1),
                _createElementVNode("p", _hoisted_69, _toDisplayString(_ctx.payFee), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.purpose && _ctx.paymentForm != 'TEF')
            ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
                _createElementVNode("p", _hoisted_71, _toDisplayString(_ctx.purposeTitle), 1),
                _createElementVNode("p", _hoisted_72, _toDisplayString(_ctx.purpose), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_73, [
            _createElementVNode("p", _hoisted_74, _toDisplayString(_ctx.consentIDTitle), 1),
            _createElementVNode("p", _hoisted_75, _toDisplayString(_ctx.consentID), 1)
          ])
        ], 2)
      ], 2)
    ])
  ], 64))
}