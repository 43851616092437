<template>
  <div class="mb-2">
    <a>
      <div class="grid box">
        <div v-bind:class="getCssClass(cardStatus)" @click="goToView('resume', id)" style="cursor: pointer;">
          <p class="card card-status-title mb-2 ml-2">{{cardStatusTitle}}</p>
          <p class="card card-status-description ml-2">{{cardStatusDescription}}</p>
        </div>
        <div class="col-12 whitebox">
          <p class="card card-info card-info-title mb-1 ml-2">{{cardInfoTitle}}</p>
          <p class="card card-info card-info-description mb-1 ml-2">{{cardInfoDescription}}</p>
        </div>
        <div class="col-12 flex justify-content-between">
          <span class="card card-info card-info-link mb-1 ml-2" @click="goToView('resume', id)">Resumo</span>
          <span class="card card-info card-info-link mb-1 mr-2" @click="goToView('history', id)">Histórico</span>
        </div>
      </div>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConsentStatusCard',
  props: {
    id: String,
    detailView: String,
    cardStatus: String,
    cardStatusTitle: String,
    cardStatusDescription: String,
    cardInfoTitle: String,
    cardInfoDescription: String,
    cardHistoryAwaitingDate: String,
    isSweepingDetail: Boolean
  },
  methods: {
    test(message: string) : void {
      console.log(message);
    },
    getCssClass(status: string) : string {
      return 'col-12 card-header card-header-' + status;
    },
    goToView(viewName: string, id: string) : void {
      sessionStorage.setItem('historyAwaitingDate', this.cardHistoryAwaitingDate ? this.cardHistoryAwaitingDate.toString() : 'invalid date');
      sessionStorage.setItem('LAST_SELECTED_CONSENT', id);
      sessionStorage.setItem('isSweepingCheck', this.isSweepingDetail.toString());
      this.$router.push({ name: 'DetailConsent', params: { detailView: viewName } });
    },
  }
});
</script>

<style scoped>

.box {
  background-color: white;
  box-shadow: var(--shadow-default);
  border-radius: 0px 0px 8px 8px;
  margin: 1em;
}

.card {
  font-style: normal;
  display: flex;
  align-items: center;
  margin:0;
}

.card-header {
  min-height: 74px;
  min-width: 312px;
  border-radius: 8px 8px 0px 0px;
  padding: 1em;
}

.card-header-waiting {
  background: var(--consultconsent-awaiting-background-color);
  color: var(--consultconsent-awaiting-text-color);
  font-weight: var(--consultconsent-text-wight);
}
.card-header-waiting:hover {
  background: var(--consultconsent-awaiting-background-hover-color);
}

.card-header-consumed {
  background: var(--consultconsent-consumed-background-color);
  color: var(--consultconsent-consumed-text-color);
  font-weight: var(--consultconsent-text-wight);
}
.card-header-consumed:hover {
  background: var(--consultconsent-consumed-background-hover-color);
}

.card-header-authorised {
  background: var(--consultconsent-authorised-background-color);
  color: var(--consultconsent-authorised-text-color);
  font-weight: var(--consultconsent-text-wight);
}
.card-header-authorised:hover {
  background: var(--consultconsent-authorised-background-hover-color);
}

.card-header-expired {
  background: var(--consultconsent-expired-background-color);
  color: var(--consultconsent-expired-text-color);
  font-weight: var(--consultconsent-text-wight);
}
.card-header-expired:hover {
  background: var(--consultconsent-expired-background-hover-color);
}

.card-header-rejected {
  background: var(--consultconsent-rejected-background-color);
  color: var(--consultconsent-rejected-text-color);
  font-weight: var(--consultconsent-text-wight);
}
.card-header-rejected:hover {
  background: var(--consultconsent-rejected-background-hover-color);
}
.card-header-revoked {
  background: var(--consultconsent-revoked-background-color);
  color: var(--consultconsent-revoked-text-color);
  font-weight: var(--consultconsent-text-wight);
}
.card-header-revoked:hover {
  background: var(--consultconsent-revoked-background-hover-color);
}

.card-status-title {
  font-weight: var(--consultconsent-box-down-title-weight);
  font-size: 16px;
  line-height: 22px;
}

.card-status-description {
  font-weight: var(--consultconsent-box-down-title-weight);
  font-size: 12px;
  line-height: 12px;
}

.card-info-title {
  color: var(--consultconsent-myconsents-box-down-title-color);
  font-weight: var(--consultconsent-box-down-title-weight);
  font-size: 20px;
  line-height: 24px;
}

.card-info-description {
  color: var(--consultconsent-myconsents-box-down-text-color);
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}
.card-info-link {
  color: var(--consultconsent-resume-history-text-color);
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  text-decoration-line: underline;
}
</style>