<template>
  <div :style="baserecstyle" class="baserectangle col-12">
    <span :style="recstyle" class="rectangle"></span>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'PanelRectangle',
    props: {
      baserecstyle: String,
      recstyle: String
    }
  };
</script>

<style scoped>

.baserectangle {
  text-align: center;
  padding: 0px;
}
.rectangle {
  display: inline-block;
  padding: none;
  border: none;
  outline: none;
  border-radius: 8px;
  width: 37px;
  height: 6px;
}

.painel-authorised .rectangle {
  background-color: var(--consultconsent-authorised-background-color);
}
.painel-awaiting .rectangle {
  background-color: var(--consultconsent-awaiting-background-color);
}
.painel-rejected .rectangle {
  background-color: var(--consultconsent-rejected-background-color);
}
.painel-revoked .rectangle {
  background-color: var(--consultconsent-revoked-background-color);
}
.painel-temporarily .rectangle {
  background-color: #999999;
}
.painel-null .rectangle {
  background-color: var(--confcons-box2-up-background-color);
}

</style>