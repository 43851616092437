<template>
  <div v-if="$route.name !== 'Redirect IF' && showManagerNav">
    <div v-if="!managerNav" id="nav" class="flex p-3 card">
      <div class="mr-2 if-container-padding" v-if="showGoBack">
        <GoBackButton :label="bntGoBackLabel" />
      </div>

      <div class="ml-auto if-container-padding">
        <IfInfo 
          :ifName="ifName"
          v-bind:width="37" v-bind:height="34">
        </IfInfo>
      </div>
      
    </div>

    <div v-else id="navManager" class="flex pt-3 pb-3 card top-bar" >

      <div class="mr-2 if-container-padding">
        <ImageFlexContainer align='tecban-center'/>
      </div>

      <div class="" style='align-self: center;'>
        <ImageFlexContainer align='open-banking-center'/>
      </div>
      
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavBar',

  props: {
    ifName: String,
    bntGoBackLabel: String,
  },

  data() {
    return {
      managerNav: false,
      showManagerNav: false,
      showGoBack: true,
    };
  },

  methods: {
    showClientNavbar(){
      this.managerNav = false;
      this.showManagerNav = true;
    },
    hideClientNavbar(){
      this.managerNav = false;
      this.showManagerNav = false;
    },
    showManagerNavbar(){
      this.managerNav = true;
      this.showManagerNav = true;
    },
    showGoBackButton() {
      this.showGoBack = true;
    },
    hideGoBackButton() {
      this.showGoBack = false;
    },
  }
});
</script>

<style scoped>

#nav {
  background-color:var(--navbar-background-color);
  /* width: 100%;
  position: absolute;
  place-content: space-between; */
}

#navManager {
  /* background-color: var(--tecban-back-color1); */
  width: 100%;
  position: absolute;
  place-content: space-between;
}

.top-bar {
  padding-left: 64px;
  padding-right: 64px;
}

</style>