<template>
  <div class="mb-2">
    <div class="card my-3">
      <p :class="linePxClass + ' ' + boldText" class="custom-word-wrap" v-html="sanitizedText"></p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import DOMPurify from 'dompurify';
import { computed, ref, watch  } from 'vue';

/**
 * linepx está configurado somente para 16 ou 20, qualquer coisa diferente precisa ser implementado
 * @prop {Number} linepx
 */
const props = defineProps({
  text: String,
  linepx: Number,
  bold: Boolean
});

const sanitizedText = ref('');

const sanitizeText = (text) => {
  return DOMPurify.sanitize(text);
};

const linePxClass = computed(() => {
  return 'line-height-' + props.linepx;
});

const boldText = computed(() => {
  if (props.bold) {
    return 'font-bold';
  } else {
    return null;
  }
});

watch(() => props.text, (newVal) => {
  sanitizedText.value = sanitizeText(newVal);
}, { immediate: true });
</script>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TextBox',

  methods: {
    test(message: string) : void {
      console.log(message);
    }
  }
});
</script>

<style scoped>

.card {
  color: var(--consultconsent-myconsents-fst-text-color);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align:left;
  /* margin: 1rem !important; */
}

.custom-word-wrap {
  white-space: pre-line;
  word-wrap: break-word;
} 

.line-height-16 {
  line-height: 16px;
}

.line-height-20 {
  line-height: 20px;
}
</style>