const AppRoutes = [
    '/consultShare',
    '/consultConsents',
    '/myConsents',
    '/selectAccount/',
    '/confirmConsent',
    '/faq',
    '/contactUs',
    '/redirect',
    '/error',
    '/home',
];

const FetchStates = {
    TecbanTimeout: 'TecbanTimeout',
    MaxTimeout: 'MaxTimeout',
    FetchError: 'FetchError'
};

const SessionObjects = {
    LAST_SELECTED_CONSENT : 'LAST_SELECTED_CONSENT',
    BACK_TO_CONSENT_LIST : 'BACK_TO_CONSENT_LIST'
};

const AccountTypes =  {
    CACC : 'CACC',
    SLRY : 'SLRY', 
    SVGS : 'SVGS',
    TRAN : 'TRAN'
};

const ConsentStatus = {
    WAITING : 'AWAITING_AUTHORISATION',
    ACCEPTED : 'AUTHORISED', 
    REJECTED : 'REJECTED',
    CONSUMED : 'CONSUMED',
    REVOKED : 'REVOKED',
    EXPIRED : 'EXPIRED',
    PARTIALLY_ACCEPTED : 'PARTIALLY_ACCEPTED',
    UNAVAILABLE: 'UNAVAILABLE',
    SCHEDULED_PAYMENTS: 'SCHD',
    LIQUIDATED_PAYMENTS: 'ACSC',
    CANCELED_PAYMENTS: 'CANC',
    PENDING_PAYMENTS: 'PDNG'
};

const PaymentTypes = {
    PIX : 'PIX',
    TED : 'TED', 
    TEF : 'TEF'
};

function GetAccountType(accountType) {

    let ret = 'Tipo de conta inválido';

    switch(accountType) {
        case AccountTypes.CACC: ret = 'Conta Corrente'; break;
        case AccountTypes.SLRY: ret = 'Conta Salário'; break;
        case AccountTypes.SVGS: ret = 'Conta Poupança'; break;
        case AccountTypes.TRAN: ret = 'Conta de Pagamento Pré-Paga'; break;
    }

    return ret;
}

function GetConsentStatus(status) {

    let ret = 'Status Indefinido';

    const internalization = getInternalization();
    
    if(internalization == null){
        switch(status) {
            case ConsentStatus.WAITING: ret = 'Aguardando Autorização'; break;
            case ConsentStatus.ACCEPTED: ret = 'Autorizado'; break;
            case ConsentStatus.REJECTED: ret = 'Rejeitado'; break;
            case ConsentStatus.CONSUMED: ret = 'Consumido'; break;
            case ConsentStatus.REVOKED: ret = 'Cancelado'; break;
            case ConsentStatus.SCHEDULED_PAYMENTS: ret = 'Agendado'; break;
            case ConsentStatus.LIQUIDATED_PAYMENTS: ret = 'Liquidado'; break;
            case ConsentStatus.CANCELED_PAYMENTS: ret = 'Cancelado'; break;
            case ConsentStatus.PENDING_PAYMENTS: ret = 'Pendente'; break;
        }
    }
    else{
        switch(status) {
            case ConsentStatus.WAITING: ret = internalization.myConsAndDetailConsUpBoxTitleWaiting; break;
            case ConsentStatus.ACCEPTED: ret = internalization.myConsAndDetailConsUpBoxTitleAccepted; break;
            case ConsentStatus.REJECTED: ret = internalization.myConsAndDetailConsUpBoxTitleRejected; break;
            case ConsentStatus.CONSUMED: ret = internalization.myConsAndDetailConsUpBoxTitleConsumed; break;
            case ConsentStatus.REVOKED: ret = internalization.myConsAndDetailConsUpBoxTitleRevoked; break;
            case ConsentStatus.SCHEDULED_PAYMENTS: ret = 'Agendado'; break;
            case ConsentStatus.LIQUIDATED_PAYMENTS: ret = 'Liquidado'; break;
            case ConsentStatus.CANCELED_PAYMENTS: ret = 'Cancelado'; break;
            case ConsentStatus.PENDING_PAYMENTS: ret = 'Pendente'; break;
        }
    }

    return ret;
}

function GetPaymentType(type) {

    let ret = 'Tipo de Pagamento Indefinido';

    const internalization = getInternalization();
    
    if(internalization == null){
        switch(type) {
            case PaymentTypes.PIX: ret = 'PIX'; break;
            case PaymentTypes.TED: ret = 'TED'; break;
            case PaymentTypes.TEF: ret = 'TEF'; break;
        }
    }
    else{
        switch(type) {
            case PaymentTypes.PIX: ret = internalization.filterClientFilteredTypePaymentPIXLabel; break;
            case PaymentTypes.TED: ret = internalization.filterClientFilteredTypePaymentTEDLabel; break;
            case PaymentTypes.TEF: ret = internalization.filterClientFilteredTypePaymentTEFLabel; break;
        }
    }

    return ret;
}

function GetConsentCssClass(status) {

    let ret = 'expired';
    
    switch(status) {
        case ConsentStatus.WAITING: ret = 'waiting'; break;
        case ConsentStatus.ACCEPTED: ret = 'authorised'; break;
        case ConsentStatus.REJECTED: ret = 'rejected'; break;
        case ConsentStatus.CONSUMED: ret = 'consumed'; break;
        case ConsentStatus.REVOKED: ret = 'revoked'; break;
        case ConsentStatus.EXPIRED: ret = 'expired'; break;
        case ConsentStatus.UNAVAILABLE: ret = 'unavailable'; break;
        case ConsentStatus.SCHEDULED_PAYMENTS: ret = 'scheduled_payments'; break;
        case ConsentStatus.LIQUIDATED_PAYMENTS: ret = 'liquidated_payments'; break;
        case ConsentStatus.CANCELED_PAYMENTS: ret = 'canceled_payments'; break;
        case ConsentStatus.PENDING_PAYMENTS: ret = 'pending_payments'; break;
    }

    return ret;
}

function MaskCpfCnpj(rawCpfCnpj, size) {
    return size == 11
        ? rawCpfCnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
        : rawCpfCnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5');
}

function MaskCpfCnpjBlur(rawCpfCnpj, size) {
    return size == 11
        ? rawCpfCnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1$2$3$4')
        : rawCpfCnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1$2$3$4$5');
}
//function MaskCpfCnpj(rawCpfCnpj, size) {
//    return size == 11
//       ? rawCpfCnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '***.$2.$3-**')
//        : rawCpfCnpj.replace(/^(\d{2})(\d{1})(\d{2})(\d{3})(\d{4})(\d{2}).*/, '**.*$3.$4/$5-**');
    
     
//}
//function MaskCpfCnpjBlur(rawCpfCnpj, size) {
//    return size == 11
//        ? rawCpfCnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '***$2$3**')
//        : rawCpfCnpj.replace(/^(\d{2})(\d{1})(\d{2})(\d{3})(\d{4})(\d{2}).*/, '***$3$4$5**');
//}

function setWithoutExpiry(key, value) {
    sessionStorage.setItem(key, value);
}
function getWithoutExpiry(key) {
	const itemStr = sessionStorage.getItem(key);
    
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}

	return itemStr;
}

function setWithExp(key, value) {
    sessionStorage.setItem(key, value);
}

function getWithExp(key) {
	const itemStr = sessionStorage.getItem(key);
    
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}

	return itemStr;
}

function setWithExpiry(key, value, ttl) {
	const now = new Date();
    const parsedExpiry = (ttl != 0 ? now.getTime() + ttl : 0);

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const data = {
		value: value,
		expiry: parsedExpiry
	};
	sessionStorage.setItem(key, JSON.stringify(data));
}
function getWithExpiry(key) {
	const itemStr = sessionStorage.getItem(key);
	// if the item doesn't exist, return null
    //console.log();
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	// compare the expiry time of the item with the current time
	if (item.expiry != 0 && now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		sessionStorage.removeItem(key);
		return null;
	}
	return item.value;
}

function endSess(key){
    sessionStorage.removeItem(key);
}

function getTheme(){
    
    if (process.env.VUE_APP_IF_NAME.toLowerCase().trim() == 'qa' && getWithoutExpiry('theme') == null){
        setWithoutExpiry('theme', 'exb');
    }
    else if (
        process.env.VUE_APP_IF_NAME.toLowerCase().trim() != 'qa' && 
        getWithoutExpiry('theme') != process.env.VUE_APP_IF_NAME.toLowerCase().trim()){

        setWithoutExpiry('theme', process.env.VUE_APP_IF_NAME.toLowerCase().trim());
    }

    return getWithoutExpiry('theme');
}

function getInternalization(){
    return JSON.parse(getWithExp('internalization'));
}

function setLogoIniciadora(logoUrl){
    sessionStorage.setItem('logoIni', logoUrl);
}

function getLogoIniciadora(){
    return sessionStorage.getItem('logoIni');
}

function utf8_to_b64( str ) {
    return window.btoa(unescape(encodeURIComponent( str )));
  }
  
function getAuthorization(){
      return 'Basic ' + utf8_to_b64(process.env.VUE_APP_CLIENT_ID + ':' + process.env.VUE_APP_CLIENT_SECRET);
}

// Valida se a urn passada é valida ou não, baseado na regex abaixo
//^urn:[a-z0-9][a-z0-9-]{1,31}:([a-z0-9()+,.:=@;$_!*'-]|%[0-9a-f]{2})+$
//urn:TECBAN:pix-178b0c69-a1cc-477e-9a2d-a831dfcaeaf4
function isAValidUrn(urn) {

    let ret = false;

    if(urn != null && urn != undefined) {
        const paragraph = urn.trim();
        const regex = /urn:[a-z0-9][a-z0-9-]{1,31}:([a-z0-9()+,.:=@;$_!*'-]|%[0-9a-f]{2})+$/igm;
        const found = paragraph.match(regex);
        ret = (found != null && found != undefined);
    }
    
    return ret;
}

function minutesToMilliseconds(minute) {
    return (minute * 60) * 1000;
}

function getTimeoutClient(isMinutes) {
    if (process.env.VUE_APP_TIMEOUT>0 && process.env.VUE_APP_TIMEOUT<=60) {
        return isMinutes?process.env.VUE_APP_TIMEOUT:minutesToMilliseconds(process.env.VUE_APP_TIMEOUT);
    } else {
        return isMinutes?5:minutesToMilliseconds(5);
    }
}

function getTimeoutManager(isMinutes) {
    if (process.env.VUE_APP_TIMEOUT_MANAGER>0 && process.env.VUE_APP_TIMEOUT_MANAGER<=60) {
        return isMinutes?process.env.VUE_APP_TIMEOUT_MANAGER:minutesToMilliseconds(process.env.VUE_APP_TIMEOUT_MANAGER);
    } else {
        return isMinutes?5:minutesToMilliseconds(5);
    }
}

async function doSomeWork(time) {
    //console.log("working...");
    await new Promise(resolve => setTimeout(resolve, time));
    //console.log("work finished!");
}
  
export 
{ 
    SessionObjects,
    AccountTypes, 
    PaymentTypes, 
    ConsentStatus,
    AppRoutes,
    FetchStates 
};

export 
{ 
    GetAccountType, 
    GetPaymentType, 
    GetConsentStatus, 
    GetConsentCssClass,
    MaskCpfCnpj,
    getTheme,
    getInternalization,
    setWithoutExpiry,
    getWithoutExpiry,
    setWithExp,
    getWithExp,
    setWithExpiry,
    getWithExpiry,
    setLogoIniciadora,
    getLogoIniciadora,
    endSess,
    isAValidUrn,
    doSomeWork,
    MaskCpfCnpjBlur,
    minutesToMilliseconds,
    getTimeoutClient,
    getTimeoutManager
};