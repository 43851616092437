import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "card my-3" }
const _hoisted_3 = ["innerHTML"]

import DOMPurify from 'dompurify';
import { computed, ref, watch  } from 'vue';

/**
 * linepx está configurado somente para 16 ou 20, qualquer coisa diferente precisa ser implementado
 * @prop {Number} linepx
 */

import { defineComponent } from 'vue';

const __default__ = defineComponent({
  name: 'TextBox',

  methods: {
    test(message: string) : void {
      console.log(message);
    }
  }
});


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
  text: String,
  linepx: Number,
  bold: Boolean
},
  setup(__props) {

const props = __props;

const sanitizedText = ref('');

const sanitizeText = (text) => {
  return DOMPurify.sanitize(text);
};

const linePxClass = computed(() => {
  return 'line-height-' + props.linepx;
});

const boldText = computed(() => {
  if (props.bold) {
    return 'font-bold';
  } else {
    return null;
  }
});

watch(() => props.text, (newVal) => {
  sanitizedText.value = sanitizeText(newVal);
}, { immediate: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: _normalizeClass([linePxClass.value + ' ' + boldText.value, "custom-word-wrap"]),
        innerHTML: sanitizedText.value
      }, null, 10, _hoisted_3)
    ])
  ]))
}
}

})