import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "card card-status card-status-title mb-2 ml-2" }
const _hoisted_3 = { class: "card card-status card-status-description ml-2" }
const _hoisted_4 = { class: "pt-2" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "card card-info-title mb-1 ml-2" }
const _hoisted_7 = {
  key: 0,
  class: "col-12"
}
const _hoisted_8 = {
  key: 0,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_9 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_10 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_11 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_12 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_13 = {
  key: 0,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_14 = {
  key: 1,
  class: "col-12"
}
const _hoisted_15 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_16 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_17 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_18 = {
  key: 2,
  class: "col-12"
}
const _hoisted_19 = {
  key: 3,
  class: "col-12"
}
const _hoisted_20 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_21 = {
  key: 4,
  class: "col-12"
}
const _hoisted_22 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_23 = {
  key: 5,
  class: "col-12"
}
const _hoisted_24 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_25 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_26 = {
  key: 6,
  class: "col-12"
}
const _hoisted_27 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_28 = {
  key: 7,
  class: "col-12"
}
const _hoisted_29 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_30 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_31 = {
  key: 8,
  class: "col-12"
}
const _hoisted_32 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_33 = {
  key: 9,
  class: "col-12"
}
const _hoisted_34 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_35 = {
  key: 10,
  class: "col-12"
}
const _hoisted_36 = {
  key: 11,
  class: "col-12"
}
const _hoisted_37 = {
  key: 12,
  class: "col-12"
}
const _hoisted_38 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_39 = {
  key: 13,
  class: "col-12"
}
const _hoisted_40 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_41 = {
  key: 14,
  class: "col-12"
}
const _hoisted_42 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_43 = {
  key: 15,
  class: "col-12"
}
const _hoisted_44 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_45 = {
  key: 16,
  class: "col-12"
}
const _hoisted_46 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_47 = {
  key: 17,
  class: "col-12"
}
const _hoisted_48 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_49 = {
  key: 18,
  class: "col-12"
}
const _hoisted_50 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_51 = {
  key: 19,
  class: "col-12"
}
const _hoisted_52 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_53 = {
  key: 20,
  class: "col-12"
}
const _hoisted_54 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_55 = {
  key: 21,
  class: "col-12"
}
const _hoisted_56 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_57 = {
  key: 22,
  class: "col-12"
}
const _hoisted_58 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_59 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_60 = {
  key: 23,
  class: "col-12"
}
const _hoisted_61 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_62 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_63 = {
  key: 24,
  class: "col-12"
}
const _hoisted_64 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_65 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_66 = {
  key: 25,
  class: "col-12"
}
const _hoisted_67 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_68 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_69 = {
  key: 26,
  class: "col-12"
}
const _hoisted_70 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_71 = { class: "col-12" }
const _hoisted_72 = { class: "card card-inner-title mb-2 ml-2" }
const _hoisted_73 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_74 = { class: "pt-2" }
const _hoisted_75 = { class: "col-12" }
const _hoisted_76 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_77 = { class: "col-12" }
const _hoisted_78 = { class: "card card-inner-text mb-1 ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.getCssClassBox(_ctx.cardStatus))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.getCssClass(_ctx.cardStatus))
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.cardStatusTitle), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.cardStatusDescription), 1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["row", _ctx.getCssClassDescriptionContainer(_ctx.cardStatus)])
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.getCssClassToggler(_ctx.cardStatus))
          }, null, 2)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.cardInfoTitle), 1)
        ]),
        (_ctx.isAutomaticPayment && _ctx.jwe?.accounts[0])
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _cache[0] || (_cache[0] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Dados Pagador", -1)),
              (_ctx.isAutomaticPayment.contractDebtor?.document?.rel)
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.isAutomaticPayment.contractDebtor?.document?.rel) + ": " + _toDisplayString(_ctx.isAutomaticPayment.contractDebtor?.document?.identification), 1))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.jwe?.accounts[0]?.owner), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "col-12",
          style: _normalizeStyle(_ctx.getCssDebtorData(_ctx.haveDebtorAccount))
        }, [
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.isAutomaticPayment ? 'Dados do recebedor' : _ctx.payerDetailTitle), 1),
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.payerCpf), 1),
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.beneficiaryName), 1),
          (_ctx.initiatorName)
            ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.initiatorName), 1))
            : _createCommentVNode("", true)
        ], 4),
        (_ctx.initiatorAccount)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.initiatorTitle), 1),
              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.initiatorBank), 1),
              _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.initiatorAccount), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isSweeping)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[1] || (_cache[1] = [
              _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Descrição da autorização", -1),
              _createElementVNode("p", { class: "card card-inner-text mb-1 ml-2" }, "Gestão Financeira Automatizada", -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.isAutomaticPayment?.contractId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Número do contrato", -1)),
              _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.isAutomaticPayment?.contractId), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.additionalInformationAutomaticPayment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Referente a:", -1)),
              _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.additionalInformationAutomaticPayment), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isAutomaticPayment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
              _createElementVNode("p", _hoisted_24, "Recorrência do pagamento (" + _toDisplayString(_ctx.isAutomaticPayment.interval.toLowerCase()) + ")", 1),
              _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.automaticPaymentIntervalLabel(_ctx.isAutomaticPayment.interval, _ctx.isAutomaticPayment.firstPayment.date)), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isAutomaticPayment?.fixedAmount)
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Valor dos pagamentos", -1)),
              _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.formatValueToReal(_ctx.isAutomaticPayment.fixedAmount)), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isAutomaticPayment?.minimumVariableAmount)
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
              _cache[5] || (_cache[5] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Limites transacionais", -1)),
              _createElementVNode("p", _hoisted_29, "Mínimo " + _toDisplayString(_ctx.formatValueToReal(_ctx.isAutomaticPayment.minimumVariableAmount)) + " " + _toDisplayString(_ctx.periodLabel(_ctx.isAutomaticPayment.interval)) + " e", 1),
              _createElementVNode("p", _hoisted_30, "Máximo " + _toDisplayString(_ctx.formatValueToReal(_ctx.isAutomaticPayment.maximumVariableAmount)) + " " + _toDisplayString(_ctx.periodLabel(_ctx.isAutomaticPayment.interval)), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isAutomaticPayment?.firstPayment?.date)
          ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
              _cache[6] || (_cache[6] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Data do primeiro pagamento", -1)),
              _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.convertUtcDateToString(_ctx.isAutomaticPayment.firstPayment.date, 'pt-BR', false)), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isAutomaticPayment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
              _cache[7] || (_cache[7] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Data de expiração da autorização", -1)),
              _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.expiryDate), 1)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isAutomaticPayment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_35, _cache[8] || (_cache[8] = [
              _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Tipo de transação", -1),
              _createElementVNode("p", { class: "card card-inner-text mb-1 ml-2" }, "PIX", -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_36, _cache[9] || (_cache[9] = [
              _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Forma de pagamento", -1),
              _createElementVNode("p", { class: "card card-inner-text mb-1 ml-2" }, "Pix Automático", -1)
            ]))),
        (!!_ctx.selectedPeriodicTotal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
              _cache[10] || (_cache[10] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Limite de todas as tranferências", -1)),
              _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.selectedPeriodicTotal), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedPeriodicTransation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
              _cache[11] || (_cache[11] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Limite por transação", -1)),
              _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.selectedPeriodicTransation), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedPeriodicLimitDay)
          ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
              _cache[12] || (_cache[12] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Limite diário", -1)),
              _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.selectedPeriodicLimitDay), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedPeriodicQuantityDay)
          ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
              _cache[13] || (_cache[13] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Quantidade de pagamentos diários", -1)),
              _createElementVNode("p", _hoisted_44, _toDisplayString(_ctx.selectedPeriodicQuantityDay), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedPeriodicLimitWeek)
          ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
              _cache[14] || (_cache[14] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Limite semanal", -1)),
              _createElementVNode("p", _hoisted_46, _toDisplayString(_ctx.selectedPeriodicLimitWeek), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedPeriodicQuantityWeek)
          ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
              _cache[15] || (_cache[15] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Quantidade de pagamentos semanais", -1)),
              _createElementVNode("p", _hoisted_48, _toDisplayString(_ctx.selectedPeriodicQuantityWeek), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedPeriodicLimitMonth)
          ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
              _cache[16] || (_cache[16] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Limite mensal", -1)),
              _createElementVNode("p", _hoisted_50, _toDisplayString(_ctx.selectedPeriodicLimitMonth), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedPeriodicQuantityMonth)
          ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
              _cache[17] || (_cache[17] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Quantidade de pagamentos mensais", -1)),
              _createElementVNode("p", _hoisted_52, _toDisplayString(_ctx.selectedPeriodicQuantityMonth), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedPeriodicLimitYear)
          ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
              _cache[18] || (_cache[18] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Limite anual", -1)),
              _createElementVNode("p", _hoisted_54, _toDisplayString(_ctx.selectedPeriodicLimitYear), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedPeriodicQuantityYear)
          ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
              _cache[19] || (_cache[19] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Quantidade de pagamentos anuais", -1)),
              _createElementVNode("p", _hoisted_56, _toDisplayString(_ctx.selectedPeriodicQuantityYear), 1)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isAutomaticPayment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
              _createElementVNode("p", _hoisted_58, _toDisplayString(_ctx.creationDateTitle), 1),
              _createElementVNode("p", _hoisted_59, _toDisplayString(_ctx.creationDate), 1)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isAutomaticPayment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
              _createElementVNode("p", _hoisted_61, _toDisplayString(_ctx.expiryDateTitle), 1),
              _createElementVNode("p", _hoisted_62, _toDisplayString(_ctx.expiryDate), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.originBankName)
          ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
              _createElementVNode("p", _hoisted_64, _toDisplayString(_ctx.originLabel), 1),
              _createElementVNode("p", _hoisted_65, _toDisplayString(_ctx.originBankName), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.payFee)
          ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
              _createElementVNode("p", _hoisted_67, _toDisplayString(_ctx.payFeeTitle), 1),
              _createElementVNode("p", _hoisted_68, _toDisplayString(_ctx.payFee), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isAutomaticPayment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
              _cache[20] || (_cache[20] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Iniciador das transferências", -1)),
              _createElementVNode("p", _hoisted_70, _toDisplayString(_ctx.tppStart), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_71, [
          _createElementVNode("p", _hoisted_72, _toDisplayString(_ctx.consentIDTitle), 1),
          _createElementVNode("p", _hoisted_73, _toDisplayString(_ctx.consentID), 1)
        ])
      ], 2)
    ], 2),
    (_ctx.cardStatus === 'authorised' && _ctx.isAutomaticPayment?.fixedAmount)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.getCssClassBox(_ctx.cardStatus))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.getCssClass(_ctx.cardStatus))
          }, _cache[21] || (_cache[21] = [
            _createElementVNode("p", { class: "card card-status card-status-title ml-2" }, "Pagamento imediato", -1)
          ]), 2),
          _createElementVNode("div", {
            class: _normalizeClass(["row", _ctx.getCssClassDescriptionContainer(_ctx.cardStatus)])
          }, [
            _createElementVNode("div", _hoisted_74, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.getCssClassToggler(_ctx.cardStatus))
              }, null, 2)
            ]),
            _createElementVNode("div", _hoisted_75, [
              _cache[22] || (_cache[22] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Referente a:", -1)),
              _createElementVNode("p", _hoisted_76, _toDisplayString(_ctx.isAutomaticPayment?.firstPayment?.remittanceInformation), 1)
            ]),
            _createElementVNode("div", _hoisted_77, [
              _cache[23] || (_cache[23] = _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Valor: ", -1)),
              _createElementVNode("p", _hoisted_78, _toDisplayString(_ctx.formatValueToReal(_ctx.isAutomaticPayment?.firstPayment?.amount)), 1)
            ]),
            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "col-12" }, [
              _createElementVNode("p", { class: "card card-inner-title mb-2 ml-2" }, "Data do pagamento"),
              _createElementVNode("p", { class: "card card-inner-text mb-1 ml-2" }, "Imediato")
            ], -1))
          ], 2)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}