import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-2 history-consent-box" }
const _hoisted_2 = { class: "card card-status card-status-title mb-2 ml-2" }
const _hoisted_3 = { class: "card card-status card-status-description ml-2" }
const _hoisted_4 = { class: "mr-1" }
const _hoisted_5 = { class: "pt-2" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "card card-info-title mb-1 ml-2" }
const _hoisted_8 = { class: "card card-info-title mb-1 ml-2 history-content" }
const _hoisted_9 = { class: "card card-inner-text mb-1 ml-2 history-content-status" }
const _hoisted_10 = { class: "card card-inner-text mb-1 ml-2" }
const _hoisted_11 = {
  key: 0,
  class: "card card-inner-text mb-1 ml-2"
}
const _hoisted_12 = {
  key: 1,
  class: "card card-inner-text mb-1 ml-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Timeline = _resolveComponent("Timeline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.getCssClassBox(_ctx.cardStatus))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.getCssClass(_ctx.cardStatus))
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.cardStatusTitle), 1),
        _createElementVNode("p", _hoisted_3, [
          _withDirectives(_createElementVNode("span", _hoisted_4, "Limite para autorização:", 512), [
            [_vShow, _ctx.cardStatusTitle === "Aguardando Autorização"]
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.isSweeping ? "" : _ctx.cardStatusTitle === 'Encerramento: ' ? 'Data de encerramento: ' : 'Data de vencimento: ') + " " + _toDisplayString(_ctx.cardStatusTitle === "Aguardando Autorização" ? _ctx.convertUtcToLocale(_ctx.cardHistoryAwaitingDate) : _ctx.convertUtcToLocale(_ctx.cardStatusDescription)), 1)
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["row", _ctx.getCssClassDescriptionContainer(_ctx.cardStatus)])
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.getCssClassToggler(_ctx.cardStatus))
          }, null, 2)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.cardInfoTitle), 1)
        ]),
        _createVNode(_component_Timeline, {
          value: _ctx.history,
          class: "history-timeline"
        }, {
          marker: _withCtx((slotProps) => [
            _createElementVNode("span", {
              class: _normalizeClass(["custom-marker shadow-2", _ctx.getCssColor(slotProps.item.status)])
            }, null, 2)
          ]),
          content: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.getStatus(slotProps.item.status)), 1),
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.convertUtcToLocale(slotProps.item.date)), 1),
              (slotProps.item.description && slotProps.item.description.trim()!=='' && slotProps.item.description.trim()!=='-')
                ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(slotProps.item.description), 1))
                : _createCommentVNode("", true),
              (slotProps.item.additionalInformation)
                ? (_openBlock(), _createElementBlock("p", _hoisted_12, "Motivo: " + _toDisplayString(slotProps.item.additionalInformation), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["value"])
      ], 2)
    ], 2)
  ]))
}